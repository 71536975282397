import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { NewsComponent } from '../news-center/components/news/news.component';
import { BulletinBoardComponent } from '../news-center/components/bulletin-board/bulletin-board.component';
import { PromotionComponent } from '../news-center/components/promotion/promotion.component';
import { PerformanceGrowthBulletinComponent } from '../news-center/components/performance-growth-bulletin/performance-growth-bulletin.component';
import { ConventionAndEventsComponent } from '../news-center/components/convention-and-events/convention-and-events.component';
import { BonusOverviewComponent } from '../bonus-and-commission/components/bonus-overview/bonus-overview.component';
import { CurrentBalanceComponent } from '../bonus-and-commission/components/current-balance/current-balance.component';
import { IncomeFlowRecordComponent } from '../bonus-and-commission/components/income-flow-record/income-flow-record.component';
import { MonthlyBonusRecordComponent } from '../bonus-and-commission/components/monthly-bonus-record/monthly-bonus-record.component';
import { UserProfileComponent } from '../my-account/components/user-profile/user-profile.component';
import { AccountRankComponent } from '../my-account/components/account-rank/account-rank.component';
import { AccountPwChangeComponent } from '../my-account/components/account-pw-change/account-pw-change.component';
import { SecurityQuestionsSettingComponent } from '../my-account/components/security-questions-setting/security-questions-setting.component';
import { AccountUploadPortraitComponent } from '../my-account/components/account-upload-portrait/account-upload-portrait.component';
import { DownlineRanksComponent } from '../team/components/downline-ranks/downline-ranks.component';
import { PersonallySponsoredChartComponent } from '../team/components/personally-sponsored-chart/personally-sponsored-chart.component';
import { PersonallySponsoredListComponent } from '../team/components/personally-sponsored-list/personally-sponsored-list.component';
import { TreeComponent } from '../team/components/tree/tree.component';
import { PersonalNewOrdersComponent } from '../orders/components/personal-new-orders/personal-new-orders.component';
import { PersonalOrdersRecordComponent } from '../orders/components/personal-orders-record/personal-orders-record.component';
import { TeamNewOrdersComponent } from '../orders/components/team-new-orders/team-new-orders.component';
import { TeamOrdersRecordComponent } from '../orders/components/team-orders-record/team-orders-record.component';
import { CompanyMessagesComponent } from '../user-help/components/company-messages/company-messages.component';
import { CustomerServiceComponent } from '../user-help/components/customer-service/customer-service.component';
import { UserFaqsComponent } from '../user-help/components/user-faqs/user-faqs.component';
import { ApplicationFormsComponent } from '../user-help/components/application-forms/application-forms.component';
import { ByteTokenTransfersComponent } from '../user-help/components/byte-token-transfers/byte-token-transfers.component';
import { MyRequestsComponent } from '../user-help/components/my-requests/my-requests.component';
import { TradePasswordComponent } from '../ewallet/components/trade-password/trade-password.component';
import { AccountComponent } from '../ewallet/components/account/account.component';
import { RenewEwalletPasswordComponent } from '../ewallet/components/renew-ewallet-password/renew-ewallet-password.component';
import { BalanceRecordComponent } from '../ewallet/components/balance-record/balance-record.component';
import { WithdrawalRequestComponent } from '../ewallet/components/withdrawal-request/withdrawal-request.component';
import { WithdrawalRequestHistoryComponent } from '../ewallet/components/withdrawal-request-history/withdrawal-request-history.component';
import { EwalletBalanceComponent } from '../ewallet/components/ewallet-balance/ewallet-balance.component';
import { TransferToCurrentBalanceComponent } from '../ewallet/components/transfer-to-current-balance/transfer-to-current-balance.component';
import { EwalletOverviewComponent } from '../ewallet/components/ewallet-overview/ewallet-overview.component';
import { PurchaseByteTokensComponent } from '../ewallet/components/purchase-byte-tokens/purchase-byte-tokens.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { PurchaseComponent } from '../purchase/purchase.component';
//import { MyShoppingStoreComponent } from '../purchase/components/my-shopping-store/my-shopping-store.component';
import { PayMonthlyFeeComponent } from '../pay-monthly-fee/pay-monthly-fee.component';
import { SetUpMonthlyPaymentComponent } from '../set-up-monthly-payment/set-up-monthly-payment.component';
import { DailySalesReportComponent } from '../admin-pages/sales-report/components/daily-sales-report/daily-sales-report.component';
import { WeeklySalesReportComponent } from '../admin-pages/sales-report/components/weekly-sales-report/weekly-sales-report.component';
import { MonthlySalesReportComponent } from '../admin-pages/sales-report/components/monthly-sales-report/monthly-sales-report.component';
import { QuarterlySalesReportComponent } from '../admin-pages/sales-report/components/quarterly-sales-report/quarterly-sales-report.component';
import { AnnualSalesReportComponent } from '../admin-pages/sales-report/components/annual-sales-report/annual-sales-report.component';
import { DailyBonusPayoutReportComponent } from '../admin-pages/commision-and-bonus-payout-reports/components/daily-bonus-payout-report/daily-bonus-payout-report.component';
import { WeeklyBonusPayoutReportComponent } from '../admin-pages/commision-and-bonus-payout-reports/components/weekly-bonus-payout-report/weekly-bonus-payout-report.component';
import { MonthlyBonusPayoutReportComponent } from '../admin-pages/commision-and-bonus-payout-reports/components/monthly-bonus-payout-report/monthly-bonus-payout-report.component';
import { QuarterlyBonusPayoutReportComponent } from '../admin-pages/commision-and-bonus-payout-reports/components/quarterly-bonus-payout-report/quarterly-bonus-payout-report.component';
import { AnnualBonusPayoutReportComponent } from '../admin-pages/commision-and-bonus-payout-reports/components/annual-bonus-payout-report/annual-bonus-payout-report.component';
import { CustomerRequestsComponent } from '../admin-pages/customer-support/components/customer-requests/customer-requests.component';
import { PostEditMessageToCustomerComponent } from '../admin-pages/customer-support/components/post-edit-message-to-customer/post-edit-message-to-customer.component';
import { PostEditBulletinBoardComponent } from '../admin-pages/customer-support/components/post-edit-bulletin-board/post-edit-bulletin-board.component';
import { PostEditCompanyNewsComponent } from '../admin-pages/customer-support/components/post-edit-company-news/post-edit-company-news.component';
import { PostEditConventionsAndEventsComponent } from '../admin-pages/customer-support/components/post-edit-conventions-and-events/post-edit-conventions-and-events.component';
import { PostEditPromotionsComponent } from '../admin-pages/customer-support/components/post-edit-promotions/post-edit-promotions.component';
import { UserApplicationsComponent } from '../admin-pages/customer-support/components/user-applications/user-applications.component';
import { GrowthBonusFundsPoolComponent } from '../admin-pages/performance-growth-bonus-setting/components/growth-bonus-funds-pool/growth-bonus-funds-pool.component';
import { GrowthBonusTopWinnersComponent } from '../admin-pages/performance-growth-bonus-setting/components/growth-bonus-top-winners/growth-bonus-top-winners.component';
import { PublishAndDistributeBonusPgComponent } from '../admin-pages/performance-growth-bonus-setting/components/publish-and-distribute-bonus-pg/publish-and-distribute-bonus-pg.component';
import { WinnersListRecordByMonthPgComponent } from '../admin-pages/performance-growth-bonus-setting/components/winners-list-record-by-month-pg/winners-list-record-by-month-pg.component';
import { LeadershipBonusFundsPoolComponent } from '../admin-pages/global-leadership-bonus-setting/components/leadership-bonus-funds-pool/leadership-bonus-funds-pool.component';
import { LeadershipBonusTopWinnersComponent } from '../admin-pages/global-leadership-bonus-setting/components/leadership-bonus-top-winners/leadership-bonus-top-winners.component';
import { PublishAndDistributeBonusGlComponent } from '../admin-pages/global-leadership-bonus-setting/components/publish-and-distribute-bonus-gl/publish-and-distribute-bonus-gl.component';
import { WinnersListRecordByMonthGlComponent } from '../admin-pages/global-leadership-bonus-setting/components/winners-list-record-by-month-gl/winners-list-record-by-month-gl.component';
import { UserRequestListComponent } from '../admin-pages/accounting/components/user-request-list/user-request-list.component';
import { CancelUserOrderComponent } from '../admin-pages/accounting/components/cancel-user-order/cancel-user-order.component';
import { WithdrawForUserComponent } from '../admin-pages/accounting/components/withdraw-for-user/withdraw-for-user.component';
import { CancelledOrdersMonthlyRecordComponent } from '../admin-pages/accounting/components/cancelled-orders-monthly-record/cancelled-orders-monthly-record.component';
import { WithdrawalMonthlyRecordComponent } from '../admin-pages/accounting/components/withdrawal-monthly-record/withdrawal-monthly-record.component';
import { TransferMonthlyRecordComponent } from '../admin-pages/accounting/components/transfer-monthly-record/transfer-monthly-record.component';
import { LockUnlockUserAccountComponent } from '../admin-pages/super/components/lock-unlock-user-account/lock-unlock-user-account.component';
import { ChangeUserRankComponent } from '../admin-pages/super/components/change-user-rank/change-user-rank.component';
import { UserDownlinesLimitComponent } from '../admin-pages/super/components/user-downlines-limit/user-downlines-limit.component';
import { RankReportsComponent } from '../admin-pages/super/components/rank-reports/rank-reports.component';
import { ProductSettingComponent } from '../admin-pages/super/components/product-setting/product-setting.component';
import { AdditionalBonusFundsPoolComponent } from '../admin-pages/additional-bonus-setting/components/additional-bonus-funds-pool/additional-bonus-funds-pool.component';
import { PublishAdditionalBonusComponent } from '../admin-pages/additional-bonus-setting/components/publish-additional-bonus/publish-additional-bonus.component';
import { PublishAdditionalBonusHistoryComponent } from '../admin-pages/additional-bonus-setting/components/publish-additional-bonus-history/publish-additional-bonus-history.component';
import { AuthchecService } from 'src/app/services/authchec.service';
import { CouponSetupComponent } from '../admin-pages/customer-support/components/coupon-setup/coupon-setup.component';
import { UserManagerComponent } from '../admin-pages/super/components/user-manager/user-manager.component';
import { PrintDeliveryTicketComponent } from '../admin-pages/customer-support/components/print-delivery-ticket/print-delivery-ticket.component';
import { BonusByteTokenTransfersComponent } from '../bonus-and-commission/byte-token-transfers/bonus-byte-token-transfers.component';
import { DailyTaxesReportComponent } from '../admin-pages/taxes-report/components/daily-taxes-report/daily-taxes-report.component';
import { WeeklyTaxesReportComponent } from '../admin-pages/taxes-report/components/weekly-taxes-report/weekly-taxes-report.component';
import { MonthlyTaxesReportComponent } from '../admin-pages/taxes-report/components/monthly-taxes-report/monthly-taxes-report.component';
import { QuarterlyTaxesReportComponent } from '../admin-pages/taxes-report/components/quarterly-taxes-report/quarterly-taxes-report.component';
import { AnnualTaxesReportComponent } from '../admin-pages/taxes-report/components/annual-taxes-report/annual-taxes-report.component';
import { RefundByteTokensComponent } from '../admin-pages/accounting/components/refund-byte-tokens/refund-byte-tokens.component';
import { SalesInvoiceComponent } from '../admin-pages/taxes-report/components/sales-invoice/sales-invoice.component';
import { SendBatchEmailComponent } from '../admin-pages/super/components/send-batch-email/send-batch-email.component';
import { SystemMaintenanceComponent } from '../admin-pages/super/components/system-maintenance/system-maintenance.component';
import { WholeOrganizationalChartComponent } from '../admin-pages/super/components/whole-organizational-chart/whole-organizational-chart.component';
import { PersonalWebpageComponent } from '../team/components/personal-webpage/personal-webpage.component';
import { InvoiceLinkComponent } from '../admin-pages/super/components/invoice-link/invoice-link.component';
import { WholesaleComponent } from '../admin-pages/super/components/wholesale/wholesale.component';
import { WithdrawalRequestsComponent } from '../admin-pages/accounting/components/withdrawal-requests/withdrawal-requests.component';
import { ViewAssetsComponent } from '../admin-pages/super/components/view-assets/view-assets.component';
import { UploadDocComponent } from '../admin-pages/customer-support/components/upload-doc/upload-doc.component';
import { AddUploadDocComponent } from '../admin-pages/customer-support/components/upload-doc/add-upload-doc/add-upload-doc.component';
import { RunAutoshipComponent } from '../admin-pages/accounting/components/run-autoship/run-autoship.component';
import { SetAutoshipComponent} from '../my-account/components/set-autoship/set-autoship.component';
import { SoldOrdersComponent } from '../admin-pages/sales-report/components/sold-orders/sold-orders.component';
import { CancelOrderComponent } from '../admin-pages/customer-support/components/cancel-order/cancel-order.component';
import { ByteTokenTrxHistoryComponent } from '../bonus-and-commission/components/byte-token-trx-history/byte-token-trx-history.component';
import { UseridWebpageComponent } from '../team/components/userid-webpage/userid-webpage.component';


const routes: Routes = [
  { path: 'backmain', component: MainComponent, canActivate: [AuthchecService],
    children: [
      { path: '',redirectTo: 'dashboard', pathMatch: 'full'},
      
      { path: 'dashboard', component : DashboardComponent },

      { path: 'account-setting', component : UserProfileComponent },
      { path: 'account-rank', component : AccountRankComponent },
      { path: 'account-pw-change', component : AccountPwChangeComponent },
      { path: 'security-questions-setting', component : SecurityQuestionsSettingComponent },
      { path: 'account-upload-portrait', component : AccountUploadPortraitComponent },
      { path: 'trade-pw-setting', component : TradePasswordComponent},
      { path: 'set-autoship', component : SetAutoshipComponent},

      { path: 'news', component: NewsComponent },
      { path: 'bulletin-board', component : BulletinBoardComponent },
      { path: 'performance-growth-bulletin', component : PerformanceGrowthBulletinComponent },
      { path: 'promotion', component : PromotionComponent },
      { path: 'convention-and-events', component : ConventionAndEventsComponent },

      { path: 'bonus-overview', component : BonusOverviewComponent },
      { path: 'current-balance', component : CurrentBalanceComponent},
      { path: 'income-flow-record', component : IncomeFlowRecordComponent},
      { path: 'monthly-bonus-record', component : MonthlyBonusRecordComponent },
      { path: 'bonus-byte-token-transfers', component : BonusByteTokenTransfersComponent },
      { path: 'byte-token-trx-history', component : ByteTokenTrxHistoryComponent},

      { path: 'tree', component : TreeComponent},
      { path: 'downline-ranks', component : DownlineRanksComponent },
      { path: 'personally-sponsored-chart', component : PersonallySponsoredChartComponent },
      { path: 'personally-sponsored-list', component : PersonallySponsoredListComponent },
      { path: 'personal-webpage', component : PersonalWebpageComponent },

      { path: 'personal-new-orders', component : PersonalNewOrdersComponent},
      { path: 'personal-orders-record', component : PersonalOrdersRecordComponent },
      { path: 'team-new-orders', component : TeamNewOrdersComponent },
      { path: 'team-orders-record', component : TeamOrdersRecordComponent },

      { path: 'personal-profile', component : AccountComponent},
      { path: 'renew-ewallet-password', component : RenewEwalletPasswordComponent },
      { path: 'balance-record', component : BalanceRecordComponent },
      { path: 'withdrawal-request', component : WithdrawalRequestComponent },
      { path: 'withdrawal-request-history', component : WithdrawalRequestHistoryComponent },
      { path: 'ewallet-balance', component : EwalletBalanceComponent },
      { path: 'trade-password', component : TradePasswordComponent},
      { path: 'transfer-to-current-balance', component : TransferToCurrentBalanceComponent },
      { path: 'ewallet-overview', component : EwalletOverviewComponent },
      { path: 'purchase-byte-tokens', component : PurchaseByteTokensComponent},

      { path: 'purchase', component : PurchaseComponent},
      //{ path: 'my-shopping-store',component : MyShoppingStoreComponent},
      { path: 'my-shopping-store',loadChildren: () => import('../purchase/components/my-shopping-store/my-shopping-store.module').then(m => m.MyShoppingStoreModule)},
      { path: 'admin/user-monthly-withdrawal-report', loadChildren: () => import('../admin-pages/user-monthly-withdrawal-report/user-monthly-withdrawal-report.module').then(m => m.UserMonthlyWithdrawalReportModule) },
      { path: 'pay-monthly-fee', component : PayMonthlyFeeComponent },
      { path: 'set-up-monthly-payment', component : SetUpMonthlyPaymentComponent },
      { path: 'company-messages', component : CompanyMessagesComponent},
      { path: 'company-messages/:id', component : CompanyMessagesComponent},
      { path: 'customer-service', component : CustomerServiceComponent},
      { path: 'user-faqs', component : UserFaqsComponent },
      { path: 'application-forms', component : ApplicationFormsComponent},
      { path: 'byte-token-transfers', component : ByteTokenTransfersComponent },
      { path: 'my-requests', component : MyRequestsComponent},
      { path: 'userid-webpage', component : UseridWebpageComponent},
      
      { path: 'admin/daily-sales-report', component : DailySalesReportComponent},
      { path: 'admin/weekly-sales-report', component : WeeklySalesReportComponent },
      { path: 'admin/monthly-sales-report', component : MonthlySalesReportComponent},
      { path: 'admin/quarterly-sales-report', component : QuarterlySalesReportComponent},
      { path: 'admin/annual-sales-report', component : AnnualSalesReportComponent },
      { path: 'admin/sold-orders', component : SoldOrdersComponent },

      { path: 'admin/daily-taxes-report', component : DailyTaxesReportComponent},
      { path: 'admin/weekly-taxes-report', component : WeeklyTaxesReportComponent },
      { path: 'admin/monthly-taxes-report', component : MonthlyTaxesReportComponent},
      { path: 'admin/quarterly-taxes-report', component : QuarterlyTaxesReportComponent},
      { path: 'admin/annual-taxes-report', component : AnnualTaxesReportComponent },
      { path: 'admin/sales-invoice', component : SalesInvoiceComponent },

      { path: 'admin/daily-bonus-payout-report', component : DailyBonusPayoutReportComponent},
      { path: 'admin/weekly-bonus-payout-report', component : WeeklyBonusPayoutReportComponent},
      { path: 'admin/monthly-bonus-payout-report', component : MonthlyBonusPayoutReportComponent },
      { path: 'admin/quarterly-bonus-payout-report', component : QuarterlyBonusPayoutReportComponent },
      { path: 'admin/annual-bonus-payout-report', component : AnnualBonusPayoutReportComponent},

      { path: 'admin/customer-requests', component : CustomerRequestsComponent },
      { path: 'admin/post-edit-message-to-customer', component : PostEditMessageToCustomerComponent},
      { path: 'admin/post-edit-message-to-customer/:id', component : PostEditMessageToCustomerComponent},
      { path: 'admin/post-edit-bulletin-board', component : PostEditBulletinBoardComponent},
      { path: 'admin/post-edit-bulletin-board/:id', component : PostEditBulletinBoardComponent},
      { path: 'admin/post-edit-company-news', component : PostEditCompanyNewsComponent },
      { path: 'admin/post-edit-company-news/:id', component : PostEditCompanyNewsComponent},
      { path: 'admin/post-edit-conventions-and-events', component : PostEditConventionsAndEventsComponent},
      { path: 'admin/post-edit-conventions-and-events/:id', component : PostEditConventionsAndEventsComponent },
      { path: 'admin/post-edit-promotions', component : PostEditPromotionsComponent },
      { path: 'admin/post-edit-promotions/:id', component : PostEditPromotionsComponent  },
      { path: 'admin/user-applications', component : UserApplicationsComponent },
      { path: 'admin/coupon-setup', component : CouponSetupComponent },
      { path: 'admin/print-delivery-ticket', component : PrintDeliveryTicketComponent },
      { path: 'admin/cancel-order', component : CancelOrderComponent },
      
      { path: 'admin/growth-bonus-funds-pool', component : GrowthBonusFundsPoolComponent},
      { path: 'admin/growth-bonus-top-winners', component : GrowthBonusTopWinnersComponent},
      { path: 'admin/publish-and-distribute-bonus-pg', component : PublishAndDistributeBonusPgComponent},
      { path: 'admin/winners-list-record-by-month-pg', component : WinnersListRecordByMonthPgComponent},

      { path: 'admin/leadership-bonus-funds-pool', component : LeadershipBonusFundsPoolComponent},
      { path: 'admin/leadership-bonus-top-winners', component : LeadershipBonusTopWinnersComponent },
      { path: 'admin/publish-and-distribute-bonus-gl', component : PublishAndDistributeBonusGlComponent},
      { path: 'admin/winners-list-record-by-month-gl', component : WinnersListRecordByMonthGlComponent },

      { path: 'admin/user-request-list', component : UserRequestListComponent },
      { path: 'admin/cancel-user-order', component : CancelUserOrderComponent },
      { path: 'admin/withdraw-for-user', component : WithdrawForUserComponent },
      { path: 'admin/cancelled-orders-monthly-record', component : CancelledOrdersMonthlyRecordComponent},
      { path: 'admin/withdrawal-monthly-record', component : WithdrawalMonthlyRecordComponent },
      { path: 'admin/transfer-monthly-record', component : TransferMonthlyRecordComponent},
      { path: 'admin/refund-byte-tokens', component : RefundByteTokensComponent},
      { path: 'admin/run-autoship', component : RunAutoshipComponent},

      { path: 'admin/lock-unlock-user-account', component : LockUnlockUserAccountComponent},
      { path: 'admin/change-user-rank', component : ChangeUserRankComponent},
      { path: 'admin/user-downlines-limit', component : UserDownlinesLimitComponent},
      { path: 'admin/rank-reports', component : RankReportsComponent },
      { path: 'admin/product-setting', component : ProductSettingComponent },
      { path: 'admin/user-manager', component : UserManagerComponent },
      { path: 'admin/send-batch-email', component : SendBatchEmailComponent },
      { path: 'admin/system-maintenance', component : SystemMaintenanceComponent },
      { path: 'admin/whole-organizational-chart', component : WholeOrganizationalChartComponent },
      { path: 'admin/view-assets', component : ViewAssetsComponent},
      { path: 'admin/additional-bonus-funds-pool', component : AdditionalBonusFundsPoolComponent},
      { path: 'admin/publish-additional-bonus', component : PublishAdditionalBonusComponent },
      { path: 'admin/publish-additional-bonus-history', component : PublishAdditionalBonusHistoryComponent },
      { path: 'admin/invoice-link', component : InvoiceLinkComponent},
      { path: 'admin/wholesale', component : WholesaleComponent},
      { path: 'admin/withdrawal-requests',component :WithdrawalRequestsComponent},
      { path: 'admin/user-monthly-withdrawal-report', loadChildren: () => import('../admin-pages/user-monthly-withdrawal-report/user-monthly-withdrawal-report.module').then(m => m.UserMonthlyWithdrawalReportModule) },
      { path: 'admin/user-monthly-transfer-report', loadChildren: () => import('../admin-pages/user-monthly-transfer-report/user-monthly-transfer-report.module').then(m => m.UserMonthlyTransferReportModule) },
      { path: 'admin/upload-doc', component : UploadDocComponent},
      { path: 'admin/add-upload-doc', loadChildren: () => import('../admin-pages/customer-support/components/upload-doc/add-upload-doc/add-upload-doc.component').then(m => m.AddUploadDocComponent) },
    ]
  }
]


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class MainRoutingModule { }
