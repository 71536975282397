
<div class="send-batch-email-container">
    <div class="row text-right">
      <button *ngIf="!isCreating" class="ml-auto mb-3 mr-3" mat-stroked-button color="primary" (click)="onCreate()">{{ 'CREATEBTN' | translate }}</button>
    </div>
    <mat-accordion *ngIf="!replyMessage && !isCreating">
      <h2>{{ 'SENDBATCHEMAIL' | translate }}</h2>
      <mat-expansion-panel [ngClass]="!email.id ? 'no-ticket' : ''" class="p-3 mb-3" hideToggle *ngFor="let email of emailList; index as i;"  (click)="getEmailById(email.id)">
        <mat-expansion-panel-header>
          <mat-panel-title class="font-weight-bold">
            {{ email.email_subject }}
          </mat-panel-title>
          <mat-panel-description class="m-auto">
              <p>
                {{ 'ID' | translate }} : <span class="font-weight-bold">{{ email.id ?  email.id : 'Unavailable' }}</span>
              </p>
              
            <!-- <quill-view [content]="news.content" format="text" theme="snow"></quill-view> -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="col-sm-12  bulletin-board-preview-container">
            <quill-view [content]="email.email_body" format="html" theme="snow"></quill-view>
          </div>
        <mat-action-row>
            <input type="email" id="email" name="email" [value]="testEmail" [(ngModel)]="testEmail" placeholder="{{'TESTEMAIL' |  translate }}">
            <button mat-stroked-button color="primary" (click)="onSendDraftToTest(i)">{{ 'SENDTESTEMAIL' | translate }}</button>
            <button mat-stroked-button color="primary" (click)="onSendAllDistributors(i)">{{ 'SENDALLDISTRIB' | translate }}</button>
            <button mat-stroked-button color="primary" (click)="onSendSelectedDistributors(i)">{{ 'SENDSELECTEDDISTRIB' | translate }}</button>
            <button mat-stroked-button color="primary" (click)="onEdit(i)">{{ 'EDITBTN' | translate }}</button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="replyMessage || isCreating" class="row text-right">
      <button class="ml-auto mr-3" mat-stroked-button color="primary" (click)="onBack()">{{ 'BACKBTN' | translate }}</button>
    </div>

    <form *ngIf="replyMessage || isCreating"  [formGroup]="emailForm" (keydown.enter)="$event.preventDefault()" autocomplete="rm-form">
    
    <div class="row gutters-sm mt-3">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                  <input matInput formControlName="email_subject"  type="text" class="form-control text-box" id="email_subject" aria-describedby="email_subject"
                  placeholder="{{ 'TOPICLABEL' | translate }}" name="email_subject" maxlength="60"
                  autocomplete="off"
                  >
                  <mat-error class="error-message" *ngIf="emailForm.get('email_subject').hasError('required')">{{ 'TOPICREQ' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <hr>
            <div class="row mb-3">
              <div class="col-sm-12">
                <h4 class="mb-0">{{ 'CONTENTLABEL' | translate }}</h4>
              </div>
              
              <div class="col-sm-12">
                    <quill-editor [placeholder]="'COMPOSENEWSDESC' | translate" formControlName="email_body">
                    </quill-editor>
              </div>
            </div>
            <br>
            <hr>

          </div>
          <div class="d-flex flex-row-reverse m-3">
            <button mat-stroked-button color="primary" [disabled]="isProcessing" (click)="postEmail()" class="align-right mr-2">{{ (selectedEmail ?'UPDATEBTN' : 'CREATEBTN') | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    
    </form>
</div>