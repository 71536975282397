import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as go from 'gojs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import LTT from 'list-to-tree' 
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { User } from 'src/app/modules/common/User';
import { CurrencyPipe } from '@angular/common';
const $ = go.GraphObject.make;

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {
  private _unsubscribe$ = new Subject<any>();
  public user : User = new User();
  public treeNodes : any = [];
  public treeData = [];
  public treeMode = 'vertical';

  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    private responseHandlerService : ResponseHandlerService,
    private shareinfoService : ShareinfoService,
    private cdr : ChangeDetectorRef,
    private currencyPipe : CurrencyPipe
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getTeamData();
    if(window.innerWidth < 1620){
      this.treeMode = "horizontal";
      this.cdr.detectChanges();
    }else{
      this.treeMode = "vertical";
      this.cdr.detectChanges();
    }
  }

  onRotate() {
    if(this.treeMode == "horizontal") {
      this.treeMode = 'vertical';
    }else if(this.treeMode == 'vertical') {
      this.treeMode = "horizontal";
    }
    this.cdr.detectChanges();
  }
  
 unflatten(arr) {
  var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

      
  // First map the nodes of the array to an object -> create a hash table.
  for(var i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.id] = arrElem;
    mappedArr[arrElem.id]['childs'] = [];
  }


  for (var id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.parentid) {
        if(mappedArr[mappedElem['parentid']]){
          mappedArr[mappedElem['parentid']]['childs'].push(mappedElem);
        }
      }
      else {
        tree.push(mappedElem);
      }
    }
  }
  
  return tree;
}

  test(event)
  {
    console.log(event);
  }

  getUser(){
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
              this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getTeamData()
  {

    this.evgService.getTeam([])
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp.success) {
          if(resp.data[0]){
            this.treeData = resp.data[0].teamtree;
            //console.log(this.treeData);

            if(this.treeData.length > 0){

              const result = this.treeData.filter(data => data.id != this.user.id);
            
              this.treeNodes = this.unflatten(this.mapData(result));
              //console.log(this.treeNodes);
            }else{
              this.treeData = [];
              this.treeNodes = [];
            }


          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  mapData(data)
  {
    let mappedData = [];

    let node = { 
      id : this.user.id,
      parentid : 0,
      name : this.user.id,
      cssClass : this.getCssClass(this.user.uLevel),
      image : 'assets/images/logo/byteToken200.png',
      assetRange : this.shareinfoService.getAssetRange((this.user.totalCash+this.user.eWallet)),
      // image : 'assets/images/icons/user.png',
      title: this.user.first_name + ' ' + this.encodeLastName(this.user.last_name) + ' - ' + this.currencyPipe.transform(this.user.teamSales)
    };

    mappedData.push(node);

    data.forEach(element => {
      
      let node = { 
        id : element.id,
        parentid : element.Referrals,
        name : element.id,
        cssClass : this.getCssClass(element.uLevel),
        image : this.user.id == element.sponsorId ? 'assets/images/icons/heart.png' : this.shareinfoService.getAssetImage(element.TotalTokens),
        title: element.first_name + ' ' + this.encodeLastName(element.last_name) + ' - ' + this.currencyPipe.transform(element.teamSales) +' - '+ 'Byte Tokens:'+this.shareinfoService.getAssetRange(element.TotalTokens),
      };
      

      mappedData.push(node);
    });

    return mappedData;
  }

  getCssClass(uLevel){
    switch (uLevel) {
      case 0: return 'member';
        break;
      case 1: return 'consultant';
        break;
      case 2: return 'distributor';
        break;
      case 3: return 'executive';
        break;
      case 4: return 'sapphire';
        break;
      case 5: return 'diamond';
        break;   
      case 6: return 'crown';
        break;               
      default: return 'member';
        break;
    }
  }

  encodeLastName(lastName){
    // console.log(lastName.length);
    if(lastName.length > 0){
       var modifiedLastName = '***';
      //  for (let index = 0; index < (lastName.length - 1); index++) {
      //    modifiedLastName += '*';
      //  }
       modifiedLastName += lastName.substr(lastName.length - 1);
       return modifiedLastName;
    }
    return '';
  }


}
