<div class="dashboard-container">
    <div class="row">
        <div class="col-lg-4">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <h2 translate>RANKSTATUS</h2>
                    <div class="p-2">
                        <p class="font-weight-bold">
                            {{ "RANK" | translate }}  
                        </p>
                        <p class="ml-4">
                            {{ rankName }}
                        </p>
                        <br>
                        <br>
                        <ng-container *ngIf="user.uLevel < 6">
                            <p class="font-weight-bold text-center text-uppercase">
                                {{ "PERCENTTILLNEXTRANK" | translate }} : <br> {{ shareinfoService.getULevelName(user.uLevel + (user.pTimes > 0 ? 1 : 0) )}}
                            </p>
                            <div class="rank-percent" *ngIf="downlines">
                                {{ shareinfoService.getNextRankPercentage(user.uLevel, user.pTimes, downlines) | percent }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="user.uLevel >= 6">
                            <p class="font-weight-bold text-center text-uppercase">
                                {{ "REACHEDHIGHESTRANK" | translate }}
                            </p>
                            <!-- <div class="rank-percent" *ngIf="downlines">
                                {{ shareinfoService.getNextRankPercentage(user.uLevel, user.pTimes, downlines) | percent }}
                            </div> -->
                        </ng-container>
                        
                    </div>
                   
                    <a *ngIf="user.pTimes > 0" class="mt-auto text-right" style="cursor: pointer;" (click)="openPage('/backmain/tree')">{{ 'VIEWMORE' | translate }}</a>
                </div>
            </div>
        </div>

        <div class="col-lg-4">

            <div class="card">
                <div class="card-body d-flex flex-column">
                    <h2 translate>METRICS</h2>
                    <div class="p-2">
                        <p class="font-weight-bold">
                            {{ 'NEWORDERS' | translate }} 
                        </p>
                        <p class="ml-4">
                            {{ newOrders ? newOrders.length : 0 }}  {{ 'MYORDERS' | translate }} - ({{ 'NEWORDERSDESC' | translate }})
                        </p>
                        <p class="font-weight-bold">
                            {{ 'MYORDERS' | translate }} 
                        </p>
                        <p class="ml-4">
                            {{ myOrders ? myOrders.length : 0}}  {{ 'MYORDERS' | translate }}
                        <p class="font-weight-bold">
                            {{ 'TEAMORDERS' | translate }}
                        </p>
                        <p class="ml-4">
                            {{ teamNewOrders ? teamNewOrders.length : 0 }} {{ 'MYORDERS' | translate }} - ({{ 'TEAMORDERSDESC' | translate }})
                        </p>
                        <p class="font-weight-bold">
                            {{ 'TEAMNEWORDERS' | translate }}
                        </p>
                        <p class="ml-4">
                            {{ teamOrders ? teamOrders.length : 0 }} {{ 'MYORDERS' | translate }} - ({{ 'TEAMNEWORDERSDESC' | translate }})
                        </p>
                    </div>
                    
                    <a class="mt-auto text-right" style="cursor: pointer;" (click)="openPage('/backmain/personal-new-orders')">{{ 'VIEWMORE' | translate }} </a>
                   
                </div>
            </div>
        </div>

        <div class="col-lg-4">

            <div class="card">
                <div class="card-body  d-flex flex-column">
                    <h2 class="mb-3" translate>RANKADV</h2>
                    <ng-container *ngIf="rankAdvancements">
                        <div class="table-responsive">
                            <table class="table table-borderless table-sm">
                                <thead>
                                  <tr>
                                    <th scope="col" translate>NAME</th>
                                    <th scope="col" translate>TO</th>
                                    <th scope="col" translate>DATE</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let rankAdvancement of rankAdvancements; index as i;">
                                    <td>{{rankAdvancement.first_name + '.' + rankAdvancement.last_name.substring(0,1)}}</td>
                                    <td>{{ shareinfoService.getULevelName(rankAdvancement.toLevel)}}</td>
                                    <td>{{ rankAdvancement.lastUpdated_at}}</td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="col-lg-4">

            <div class="card">
                <div class="card-body d-flex flex-column">
                    <h2 translate>COMPANYNEWS</h2>

                    <ng-container *ngIf="news">
                        <h1 class="font-weight-bold text-center mt-3 mb-3">{{ news.title }}</h1>

                        <quill-view [content]="news.content" format="html" theme="snow"></quill-view>
                    </ng-container>
                    

                    <a *ngIf="user.pTimes > 0" class="mt-auto text-right" style="cursor: pointer;" (click)="openPage('/backmain/news')">{{ 'VIEWMORE' | translate }}</a>
                </div>
            </div>
        </div>

        <div class="col-lg-4">

            <div class="card">
                <div class="card-body d-flex flex-column">
                    <h2 class="mb-3" translate>PERFGROWTHBUL</h2>
                    <ng-container *ngIf="bulletinRanks">
                        <div class="table-responsive">
                            <table class="table table-borderless table-sm">
                                <thead>
                                  <tr>
                                    <th class="text-center" scope="col" translate>RANK</th>
                                    <th class="text-center" scope="col" translate>NAME</th>
                                    <th class="text-center" scope="col" translate>BONUSAMOUNT</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let bulletinRank of bulletinRanks; index as i;">
                                    <th class="text-center" scope="row">{{bulletinRank.rank}}</th>
                                    <td class="text-center">{{ bulletinRank.name}}</td>
                                    <td class="text-center">{{ bulletinRank.bonusAmt | currency:'' }}</td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                    </ng-container>
                    <a *ngIf="user.pTimes > 0" class="mt-auto text-right" style="cursor: pointer;"  (click)="openPage('/backmain/performance-growth-bulletin')">{{ 'VIEWMORE' | translate }}</a>
                </div>
            </div>
        </div>


        
    </div>
</div>