import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Bonus } from 'src/app/modules/common/Bonus';
import { BulletinBoard } from 'src/app/modules/common/BulletinBoard';
import { User } from 'src/app/modules/common/User';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { Sort } from '@angular/material/sort';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { Transaction } from 'src/app/modules/common/Transaction';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/modules/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-bonus-overview',
  templateUrl: './bonus-overview.component.html',
  styleUrls: ['./bonus-overview.component.scss']
})
export class BonusOverviewComponent implements OnInit {

  public user: User;
  public bonuses : Bonus[] = [];
  public sortedBonuses : Bonus[];
  public totalBonus = 0;
  public totalInPendingBonus = 0;
  public bonusAvailable = 0;
  public bonusAvailablePercentage = 0;
  public bonusInCooling = 0;
  public bonusInCoolingPercentage = 0;
  public isLoading = true;
  private _unsubscribe$ = new Subject<any>();
  public isMonthlyFeePaid : boolean = false;
  public downlines = [];
  constructor(
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getBonuses();
    this.getMonthlyPaid();
    this.getMyDownLinelist();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getMyDownLinelist(){
    this.evgService.getMyDownLinelist({}).pipe( takeUntil(this._unsubscribe$) )
    .subscribe(
      (successResponse: any) => {
          if(successResponse.success){
            this.downlines = successResponse.data[0].results;
          }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      }
    );
  }

  getBonuses()
  {
    this.isLoading = true;
    this.evgService.getBonus( { dateId : 2} ).subscribe(resp => {
      if (resp.success) {
        this.bonuses = resp.data[0].results;
        //console.log(this.bonuses);
        this.sortedBonuses = this.bonuses.slice();
        this.calculateTotals();
      }
      this.isLoading = false;
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.isLoading = false;
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  getMonthlyPaid()
  {
    this.evgService.checkMonthFeeIsPaid ( {} ).subscribe(resp => {
      if (resp.success) {
        
        //console.log("MONTHLY",resp);
        this.isMonthlyFeePaid = resp.data[0].results.length > 0 ? true : false;
        
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    }); 
  }

  calculateTotals(){
    this.totalBonus = 0;
    this.totalInPendingBonus = 0;
    this.bonuses.forEach(element => {
      this.totalBonus += element.amount;
      this.totalInPendingBonus += element.inPending ? element.amount : 0;
    });
    this.bonusAvailable = this.totalBonus - this.totalInPendingBonus;
    this.bonusAvailablePercentage = (this.bonusAvailable/this.totalBonus) * 100;
    this.bonusInCooling = this.totalInPendingBonus;
    this.bonusInCoolingPercentage = (this.totalInPendingBonus/this.totalBonus) * 100;
  }

  sortData(sort: Sort) {
    const data = this.bonuses.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedBonuses = data;
      return;
    }

    this.sortedBonuses = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dealDate': return this.sharedUtilService.compare(a.dealDate, b.dealDate, isAsc);
        case 'MemberNo': return this.sharedUtilService.compare(a.MemberNo, b.MemberNo, isAsc);
        case 'dealName': return this.sharedUtilService.compare(a.dealName, b.dealName, isAsc);
        case 'levels': return this.sharedUtilService.compare(a.levels, b.levels, isAsc);
        case 'amount': return this.sharedUtilService.compare(a.amount, b.amount, isAsc);
        case 'inPending': return this.sharedUtilService.compare(a.inPending, b.inPending, isAsc);
        default: return 0;
      }
    });
  }



  
}
