<div class="withdrawal-request-container">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <div  class="table-responsive">
        <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{ 'BANKNAME' | translate }}</th>
                <th scope="col">{{ 'ACCOUNTNUMBER' | translate }}</th>
                <th scope="col">{{ 'WITHDRAWAMOUNT' | translate }}</th>
                <th scope="col">{{'REQUESTDATE' | translate}}</th>
                <th scope="col">{{'STATUS' | translate}}</th>
                <th scope="col">{{'OPERATION' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of withdrawalRequests; let i = index">
                <th scope="row">{{i+1}}</th>
                <td>{{item.BankName}}</td>
                <td>{{item.AccountNo}}</td>
                <td>{{item.amount}}</td>
                <td>{{item.AddTime}}</td>
                <td>{{item.statusDes}}</td>
                <td><div *ngIf="!item.status && !item.finished">
                    <button mat-mini-fab  color="primary" (click)="handleBuild(item.id)">
                    <mat-icon>build</mat-icon>
                  </button> 
                |  <button mat-mini-fab color="primary" (click)="handleCancel(item.id)">
                    <mat-icon>delete</mat-icon>
                  </button></div></td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
    </div>

        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body d-flex flex-column">
                        <div class="p-3">
                            <h2 ngbAutofocus #pagetitle>{{ 'WITHDRAWALREQUEST' | translate }}</h2>
                        </div>
                        <div>
                            <form #WithdrawalRequestF="ngForm" [formGroup]="withdrawalRequestForm" autocomplete="wr-form">
                            <div class="form-group">
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="withdraw_amount"  type="number" class="form-control text-box" id="withdraw_amount"
                                    name="withdraw_amount"  placeholder="{{ 'WITHDRAWAMOUNT' | translate }}"
                                    autocomplete="off"
                                    >
                                    <p class="text-danger small" *ngIf="withdrawalRequestForm.get('withdraw_amount').hasError('required')">{{'WITHDRAWAMOUNTREQ' | translate}}</p>
                                    <p class="text-danger small" *ngIf="withdrawalRequestForm.get('withdraw_amount').hasError('min')">{{'AMOUNTMIN50' | translate}}</p>
                                </mat-form-field>
                                </div>
                            </div>
                        
                            <div class="row">
                                <div class="col-sm-12">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="full_name_of_account_holder"  type="text" class="form-control" id="full_name_of_account_holder" aria-describedby="full_name_of_account_holder"
                                        name="full_name_of_account_holder" placeholder="{{ 'FULLNAMEOFACCOUNTHOLDER' | translate }}"
                                        autocomplete="off" 
                                        >
                                        <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('full_name_of_account_holder').hasError('required')">{{'FULLNAMEOFACCOUNTHOLDER' | translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <h3>Withdrawal Type</h3>
                            <mat-radio-group formControlName="withdrawal_type">
                            <div class="row">
                                    <div class="col-sm-6">
                                    <div class="form-control">
                                        <mat-radio-button  value="0"><span style="width: 100%;">{{'SWIFT'}}</span></mat-radio-button>
                                    </div>
                                </div>                           
                                <div class="col-sm-6">
                                    <div class="form-control">
                                        <mat-radio-button  value="1"><span style="width: 100%;">{{'ACH'}}</span></mat-radio-button>
                                    </div>
                                </div>
                            </div>
                           </mat-radio-group>
                           <hr>


                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="swift_aba_code"  type="text" class="form-control" id="swift_aba_code" aria-describedby="swift_aba_code"
                                    name="swift_aba_code" placeholder="{{ 'SWIFTABACODEACHROUTING' | translate }}"
                                    autocomplete="off" 
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('swift_aba_code').hasError('required')">{{'SWIFTABACODEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>

                            <!--<div class="row" *ngIf="withdrawal_type==1">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="ach_routing_number"  type="text" class="form-control" id="ach_routing_number" aria-describedby="ach_routing_number"
                                    name="ach_routing_number" placeholder="{{ 'ACHROUTINGNUMBER' | translate }}"
                                    autocomplete="off" 
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('ach_routing_number').hasError('required')">{{'SWIFTABACODEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>-->

                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="institution_number"  type="text" class="form-control" id="institution_number" aria-describedby="institution_number"
                                    name="institution_number" placeholder="{{ 'INSNUMBER' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('institution_number').hasError('required')">{{'INSNUMBERREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="branch_number"  type="text" class="form-control text-box" id="branch_number" aria-describedby="branch_number"
                                    name="branch_number" placeholder="{{ 'BRANCHNUMBER' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('branch_number').hasError('required')">{{'BRANCHNUMBERREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="account_number"  type="text" class="form-control text-box" id="account_number" aria-describedby="account_number"
                                    name="account_number" placeholder="{{ 'ACCOUNTNUMBER' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('account_number').hasError('required')">{{'ACCOUNTNUMBERREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="bank_name"  type="text" class="form-control text-box" id="bank_name" aria-describedby="bank_name"
                                    name="bank_name" placeholder="{{ 'BANKNAME' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('bank_name').hasError('required')">{{'BANKNAMEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="bank_address"  type="text" class="form-control text-box" id="bank_address" aria-describedby="bank_address"
                                    name="bank_address" placeholder="{{ 'BANKADDRESS' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('bank_address').hasError('required')">{{'BANKADDRESSREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="receiver_name"  type="text" class="form-control text-box" id="receiver_name" aria-describedby="receiver_name"
                                    name="receiver_name" placeholder="{{ 'RECEIVERNAME' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('receiver_name').hasError('required')">{{'RECEIVERNAMEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="receiver_address"  type="text" class="form-control text-box" id="receiver_address" aria-describedby="receiver_address"
                                    name="receiver_address" placeholder="{{ 'RECEIVERADDRESS' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('receiver_address').hasError('required')">{{'RECEIVERADDRESSREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="receiver_city"  type="text" class="form-control text-box" id="receiver_city" aria-describedby="receiver_city"
                                    name="receiver_city" placeholder="{{ 'RECEIVERCITY' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('receiver_city').hasError('required')">{{'RECEIVERCITYREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="receiver_province"  type="text" class="form-control text-box" id="receiver_province" aria-describedby="receiver_province"
                                    name="receiver_province" placeholder="{{ 'RECEIVERPROVINCESTATE' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('receiver_province').hasError('required')">{{'RECEIVERPROVINCEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="receiver_postcode"  type="text" class="form-control text-box" id="receiver_postcode" aria-describedby="receiver_postcode"
                                    name="receiver_postcode" placeholder="{{ 'RECEIVERPOSTCODE' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('receiver_postcode').hasError('required')">{{'POSTALCODEREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="receiver_country"  type="text" class="form-control text-box" id="receiver_country" aria-describedby="receiver_country"
                                    name="receiver_country" placeholder="{{ 'RECEIVERCOUNTRY' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('receiver_country').hasError('required')">{{'RECEIVERCOUNTRYREQ' | translate}}</mat-error>
                                </mat-form-field>
                                </div>
                            </div>

                            <h3>Account Type</h3>
                            <mat-radio-group formControlName="account_type">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-control">
                                        <mat-radio-button  value="0"><span style="width: 100%;">{{'CHECKING' | translate}}</span></mat-radio-button>
                                    </div>
                                </div>
                           
                                <div class="col-sm-6">
                                    <div class="form-control">
                                        <mat-radio-button  value="1"><span style="width: 100%;">{{'SAVINGS' | translate}}</span></mat-radio-button>
                                    </div>
                                </div>
                            </div>
                           </mat-radio-group>
                           <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field style="width: 100%;">
                                    <mat-hint>{{ 'TRADEPASSWORDHINT' | translate }}</mat-hint>
                                    <input matInput formControlName="tradePassword"  type="password" class="form-control text-box" id="tradePassword"
                                    name="tradePassword"  placeholder="{{ 'TRADEPASSWORD' | translate }}"
                                    autocomplete="off"
                                    >
                                    <mat-error class="error-message" *ngIf="withdrawalRequestForm.get('tradePassword').hasError('required')">{{'TRADEPASSWORDREQ' | translate}}</mat-error>
                                </mat-form-field>
                                <span class="tradePassNotice" *ngIf="tradePass_error">{{ 'TRADEPASSWORDHINT' | translate }}</span>
                            </div>
                        </div>
                        <br>

                            <div style="text-align: center; margin: auto;">
                                <button mat-stroked-button type="submit" color="primary" class="btn submit-button" [disabled]="!withdrawalRequestForm.valid" (click)="withdrawalRequest(WithdrawalRequestF)" >{{ "UPDATE" | translate}}</button>
                            </div>
                        
                            </div>
                            </form>
                            
                            </div><!--swift-->
                           
                   <!-- big form -->
                </div>
            </div>
        </div>
        
    </div>
</div>