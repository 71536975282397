
<div class="company-news-container">
    <div class="row text-right">
      <button *ngIf="!news && !isCreating" class="ml-auto mb-3 mr-3" mat-stroked-button color="primary" (click)="onCreate()">{{ 'CREATEBTN' | translate }}</button>
    </div>
    <mat-accordion *ngIf="!news && !isCreating">
      <h2>{{ 'NEWS' | translate }}</h2>
      <mat-expansion-panel hideToggle *ngFor="let news of newsList; index as i;">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ news.title }}
          </mat-panel-title>
          <mat-panel-description>
            <!-- <quill-view [content]="news.content" format="text" theme="snow"></quill-view> -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="col-sm-12  company-news-preview-container">
          <quill-view [content]="news.content" format="html" theme="snow"></quill-view>
        </div>
        <mat-action-row>
          <!-- <button mat-stroked-button color="error">{{ 'DELETEBTN' | translate }}</button> -->
          <button mat-stroked-button color="primary" (click)="onEdit(i)">{{ 'EDITBTN' | translate }}</button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="news || isCreating" class="row text-right">
      <button class="ml-auto mr-3" mat-stroked-button color="primary" (click)="onBack()">{{ 'BACKBTN' | translate }}</button>
    </div>

    <form #newsDirective="ngForm" *ngIf="news || isCreating"  [formGroup]="newsForm" (keydown.enter)="$event.preventDefault()" autocomplete="n-form">
    
    <div class="row gutters-sm mt-3">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <mat-form-field style="width: 100%;">
                  <input matInput formControlName="title"  type="text" class="form-control text-box" id="title" aria-describedby="title"
                  placeholder="{{ 'TITLELABEL' | translate }}" name="title" maxlength="60"
                  autocomplete="off"
                  >
                  <mat-error class="error-message" *ngIf="newsForm.get('title').hasError('required')">{{ 'TITLEISREQ' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <hr>
            <div class="row mb-3">
              <div class="col-sm-12">
                <h4 class="mb-0">{{ 'CONTENTLABEL' | translate }}</h4>
              </div>
              
              <div class="col-sm-12">
                <quill-editor [placeholder]="'COMPOSENEWSDESC' |translate" formControlName="content">
                </quill-editor>
              </div>
            </div>
            <br>
            <hr>

          </div>
          <div class="d-flex flex-row-reverse m-3">
            <button mat-stroked-button color="primary" [disabled]="isProcessing1"  *ngIf="!news" (click)="postNews(newsDirective)" class="align-right mr-2">{{ 'CREATEBTN' | translate }}</button>
            <button mat-stroked-button color="primary" [disabled]="isProcessing2" *ngIf="news" (click)="modifyNews(newsDirective)" class="align-right mr-2">{{ 'UPDATEBTN' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    
    </form>
</div>