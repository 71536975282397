export class User {
    id:number;
    first_name:string;   //contact
    last_name:string;    //contact
    email:string;       //contact
    password:string;    // encrypted by MD5
    mobile:string; 
    Tel:string;       //contact
    Address : string;
    Sex : string;
    Birthday:string;
    eWallet:number;
    usertokend:string;
    buyTimes: number;
    Country:string;
    Province : string;
    PostCode : string;
    language : string;
    City:string;
    isActive : boolean;
    Referrals : number;
    Recommended : number;
    uLevel : number;
    totalCash : number;
    totalSpent : number;
    pendingLayer : number;
    pendinghit : number;
    photoPath : string;
    isLocked : number;
    lastLogDate : string;
    lastLogIP : string;
    created_at: string;
    education_level : string;
    annual_income : number;
    askSecretQ : number;
    tradePass : string;
    userStrId : string;
    pTimes : number;
    payFee : number;
    autoPay : number;
    quitRank : number;
    isDistributor : number;
    teamSales : number;
    autoship: number=1;
    pre_sponsor:any;
    showMenus:number=0;
}

export class Card{
    id : number;
    cardType:  number;
    holder :   string;
    cardNo:  string;isDistributor
    expireDate : string;
    cvCode : string;
}

export class UserRequest{
    AddTime: string;
    IsReplay: number;
    ReplayTime: string;
    aType: number;
    amount: number;
    bank_id: number;
    closed_at: string;
    content: string;
    groupId: null
    id: number;
    isClosed: number;
    readFlag: number;
    readTime: string;
    receiver: number;
    requestType: number;
    result: string;
    rootId: null
    sender: number;
    ticketNo: null
    topic: string;
}

export class UserApplication{
    AddTime: string;
    IsReplay: number;
    ReplayTime: string;
    aType: number;
    amount: number;
    bank_id: number;
    closed_at: string;
    content: string;
    groupId: null
    id: number;
    isClosed: number;
    readFlag: number;
    readTime: string;
    receiver: number;
    requestType: number;
    result: string;
    rootId: null
    sender: number;
    ticketNo: null
    topic: string;
}