<div class="header" [ngClass]="currentRoute.includes('backmain') ? 'header-relative' : ''">
    <nav class="navbar-black" *ngIf="!currentRoute.includes('backmain')" >
        <div class="d-flex justify-content-end flex-wrap align-items-center p-2">
            <ng-container *ngIf="data.length > 0">
                <div class="header-email p-2" *ngIf="data[0] && data[1]">
                    {{ 'INVITEDBY' | translate }} 
                    {{ redact(data[1])}}(ID:{{redact(data[0])}})
                    {{ showOption(data[2],'EMAILADR')}} {{ data[2]? (' : ' + redact(data[2])) :''}}, 
                    {{ showOption(data[3],'PPTYPEDATA1DESC1LIST3')}} {{ data[3]? (' : '+ redact(data[3])) :''}}
                </div>
                <ng-container *ngIf="!data[0] && !data[1] ">
                    <div class="header-email p-2">support1@bytedailywellness.com</div>
                    <div class="header-phone p-2">Call Us at: 1-888-884-2915</div>
                </ng-container>
                <div class="byte-cart">
                    <a class="nav-link cart" data-toggle="collapse" style="color: rgb(171, 206, 53);" data-target=".navbar-collapse.show" (click)="showCartView()" translate>
                        <i class="fa fa-shopping-cart" style="font-size : 1.5rem; position : relative;color: rgb(171, 206, 53);" aria-hidden="true"><span class="badge" *ngIf="cart_quantity > 0">{{ cart_quantity }}</span></i>
                    </a>
                </div>
                <!-- <div class="header-phone p-2">Call Us at: 1-888-884-2915</div> -->
            </ng-container>
            <ng-container *ngIf="data.length == 0">
                <div class="header-email p-2">support1@bytedailywellness.com</div>
                <div class="header-phone p-2">Call Us at: 1-888-884-2915</div>
                <div class="byte-cart">
                    <a class="nav-link cart" data-toggle="collapse" style="color: rgb(171, 206, 53);" data-target=".navbar-collapse.show" (click)="showCartView()" translate>
                        <i class="fa fa-shopping-cart" style="font-size : 1.5rem; position : relative;color: rgb(171, 206, 53);" aria-hidden="true"><span class="badge" *ngIf="cart_quantity > 0">{{ cart_quantity }}</span></i>
                    </a>
                </div>
            </ng-container>
            
            <div class="d-flex flex-wrap flex-md-row flex-column p-2 text-center">
                <a class="button-link" (click)="openPage('byte-shop')" [ngClass]="(shareinfoService.isLoggedIn() || (currentRoute == '/byte-shop') || !isMobileMenuOpen) ? 'hidden-class' : ''" translate>SHOP</a>&nbsp;
                <a class="button-link mr-2" (click)="openPage('login')" [ngClass]="shareinfoService.isLoggedIn() ? 'hidden-class' : ''"  translate>LOGIN</a>
                &nbsp;&nbsp;&nbsp;
                <a class="button-link" (click)="openPage('register')" [ngClass]="shareinfoService.isLoggedIn() ? 'hidden-class' : ''" translate>REGISTER</a>
                <a class="button-link mr-2" (click)="logout()" [ngClass]="shareinfoService.isLoggedIn() ? '' : 'hidden-class'"  translate>LOGOUT</a>
                <a class="button-link" (click)="openPage('backmain/dashboard')" [ngClass]="shareinfoService.isLoggedIn() ? '' : 'hidden-class'" translate>MYACCOUNT</a>
            </div>
        </div>
    </nav>
    <nav id="main-nav"  class="navbar navbar-expand-lg navbar-dark">
          <a class="navbar-brand" (click)="openPage('')" href="/" >
            <!---
            <img class="logo horizontal-logo ml-5" src="./../../../../assets/images/logo/bhm_logo.png" alt="forecastr logo">
            -->
            <img class="logo horizontal-logo" src="./../../../../assets/images/logo/byte_logo_new_txt.png" alt="forecastr logo">
          </a>
        
        <button *ngIf="!currentRoute.includes('backmain')"  class="navbar-toggler" type="button" data-toggle="collapse"  data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon" style="right: 0;"></span>
        </button>
        
          
          <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="!currentRoute.includes('backmain')" >
            <ul class="navbar-nav ml-auto">
                <li class="nav-item pr-2">
                    <a class="nav-link" [ngClass]="(currentRoute == '/' || currentRoute == '/home') ? 'selected-page' : ''" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/home" (click)="openPage('home')" routerLinkActive="active" translate>HOME</a>
                </li>
                <li class="nav-item dropdown" >
                    <a translate class="nav-link" [ngClass]="(currentRoute == '/water-truth' || currentRoute == '/about' || currentRoute == '/water-analysis') ? 'selected-page' : ''" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" routerLink="/about">
                        ABOUT
                        &#9660;
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
                        <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/water-truth' ? 'active' : ''" href="/water-truth" (click)="openPage('water-truth')" translate>TWTRUTH</a>
                        <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/about' ? 'active' : ''" routerLink="/about" (click)="openPage('about')" translate>ABOUTUS</a>
                        <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/water-analysis' ? 'active' : ''" href="/water-analysis" (click)="openPage('water-analysis')" translate>WNALYSIS</a>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a translate class="nav-link" [ngClass]="(currentRoute == '/products' || currentRoute == '/whole-house-system'  || currentRoute == '/washer'  || currentRoute == '/air-purifier' || currentRoute == '/pet-trace'  || currentRoute == '/pet-c60') ? 'selected-page' : ''"  id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="vertical-align:middle">
                        PRODUCTS
                        &#9660;
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                        <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/carbom60' ? 'active' : ''" href="/carbon60" (click)="openPage('carbon60')" translate>CARBON60PAGE</a>
                        <div class="dropdown dropend" id="submenuForPet">
                            <a class="nav-link dropdown-toggle ml-3" role="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style="vertical-align:middle">
                                {{ 'PETPRODUCTS' | translate }}
                            </a>
                            <ul class="dropdown-submenu" >
                              <li class="nav-item dropend">
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/pet-c60' ? 'active' : ''" href="/pet-c60" (click)="openPage('pet-c60')" translate>PETC60PAGE</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/pet-trace' ? 'active' : ''" href="/pet-trace" (click)="openPage('pet-trace')" translate>PETTRACEELEPAGE</a>
                                </div>
                              </li>
                            </ul>
                        </div>                        
                        <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/hydrogen-energy' ? 'active' : ''" href="/hydrogen-energy" (click)="openPage('hydrogen-energy')">{{ 'HENERGYTITLE' | translate }}</a>
                        <div class="dropdown dropend" id="submenuForPet">
                            <a class="nav-link dropdown-toggle ml-3" role="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style="vertical-align:middle">
                                {{ 'FULVIC-ACID' | translate }}
                            </a>
                            <ul class="dropdown-submenu" >
                              <li class="nav-item dropend">
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/fulvic-acid' ? 'active' : ''" href="/fulvic-acid" (click)="openPage('fulvic-acid')" translate>FULVICTITLE</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/fulvic-mucuna-pruriens' ? 'active' : ''" href="/fulvic-mucuna-pruriens" (click)="openPage('fulvic-mucuna-pruriens')" translate>FULVIC-MUCUNA-PRURIENS</a>
                                </div>
                              </li>
                            </ul>
                        </div>
                        
                        <div class="dropdown dropend" id="submenuForPet">
                            <a class="nav-link dropdown-toggle ml-3" role="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style="vertical-align:middle">
                                {{ 'SKINCARE' | translate }}
                            </a>
                            <ul class="dropdown-submenu" >
                              <li class="nav-item dropend">
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/restorative' ? 'active' : ''" href="/restorative" (click)="openPage('restorative')" translate>RESTORPAGE</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/hydrating-serum' ? 'active' : ''" href="/hydrating-serum" (click)="openPage('hydrating-serum')" translate>HYDRSERUMPAGE</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/wrinkle-serum' ? 'active' : ''" href="/wrinkle-serum" (click)="openPage('wrinkle-serum')" translate>WRINKLESERUMPAGE</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/firming-cream' ? 'active' : ''" href="/firming-cream" (click)="openPage('firming-cream')" translate>FIRMINGCREAMPAGE</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/night-cream' ? 'active' : ''" href="/night-cream" (click)="openPage('night-cream')" translate>NIGHTCREAMPAGE</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/tinted-pore' ? 'active' : ''" href="/tinted-pore" (click)="openPage('tinted-pore')" translate>TINTEDPOREPAGE</a>
                                </div>
                              </li>
                            </ul>
                        </div>
                        <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/products' ? 'active' : ''" href="/products" (click)="openPage('products')" translate>PULTIMATE</a>
                        <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/products' ? 'active' : ''" href="/ultimate-ro-system" (click)="openPage('ultimate-ro-system')" translate>ULTIMATE-RO-SYSTEM</a>
                        <!-- <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/whole-house-system' ? 'active' : ''" href="/whole-house-system" (click)="openPage('whole-house-system')" translate>PWHOUSESYS</a> -->
                        <!-- <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/warranty' ? 'active' : ''" href="/warranty" (click)="openPage('warranty')" translate>PWARRANTY</a> -->
                        <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/washer' ? 'active' : ''" href="/washer" (click)="openPage('washer')" translate>PWASHER</a>
                        <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/air-purifier' ? 'active' : ''" href="/air-purifier" (click)="openPage('air-purifier')" translate>PPURIFIER</a>
                        <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/testimonials' ? 'active' : ''" href="/testimonials" (click)="openPage('testimonials')" translate>TESTIMONIALS</a>
                        <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="activeRoute == '/download-centre' ? 'active' : ''" href="/download-centre" (click)="openPage('download-centre')" translate>DOWNLOADCENTRE</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="collapse" [ngClass]="(currentRoute == '/byte-shop') ? 'selected-page' : ''"   data-target=".navbar-collapse.show" (click)="openPage('byte-shop')" routerLink="/byte-shop" translate>SHOP</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" [ngClass]="(currentRoute == '/news') ? 'selected-page' : ''"   data-target=".navbar-collapse.show" (click)="openPage('news')" routerLink="/news" translate>NEWS</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" style="text-transform: uppercase;" data-toggle="collapse" [ngClass]="(currentRoute == '/blog') ? 'selected-page' : ''"   data-target=".navbar-collapse.show" (click)="openPage('blog')"  translate>BLOG</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" [ngClass]="(currentRoute == '/contact') ? 'selected-page' : ''" data-target=".navbar-collapse.show" (click)="openPage('contact')" translate>CONTACT</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" [ngClass]="(currentRoute == '/videos') ? 'selected-page' : ''" data-target=".navbar-collapse.show" (click)="openPage('videos')" translate>VIDEOS</a>
                </li>
                <li class="nav-item" style="width: 1px;height: 1px;">
                    <a class="nav-link" data-toggle="collapse" [ngClass]="(currentRoute == '/byteCard') ? 'selected-page' : ''" data-target=".navbar-collapse.show" routerLink="/byteCard"></a>
                </li>
                <!-- admin/send-batch-email for test
                <li class="nav-item">
                    <a class="nav-link" data-toggle="collapse" [ngClass]="(currentRoute == '/batchemail') ? 'selected-page' : ''" data-target=".navbar-collapse.show" (click)="openPage('backmain/admin/send-batch-email')">batchemail</a>
                </li>
                -->
                <!-- for test
                <li class="nav-item">
                    <a class="nav-link" data-toggle="collapse" [ngClass]="(currentRoute == '/payment') ? 'selected-page' : ''" data-target=".navbar-collapse.show" (click)="openPage('payment')" translate>payment</a>
                </li>
                -->
    
                <!-- <li  class="nav-item " data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="shareinfoService.isLoggedIn() ? 'hidden-class' : ''"><a class="nav-link" (click)="openPage('login')" translate>LOGIN</a></li>
                <li  class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="shareinfoService.isLoggedIn() ? 'hidden-class' : ''"><a class="nav-link" (click)="openPage('register')" translate>REGISTER</a></li>
                <li  class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="shareinfoService.isLoggedIn() ? '' : 'hidden-class'"><a class="nav-link" (click)="logout()" translate>LOGOUT</a></li>
                <li  class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="shareinfoService.isLoggedIn() ? '' : 'hidden-class'"><a class="nav-link"  (click)="openPage('backmain/dashboard')" href="backmain/dashboard" translate>MYACCOUNT</a></li> -->
                <!-- <li  class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="shareinfoService.isLoggedIn() ? '' : 'hidden-class'">
                    
                    <a class="nav-link text-uppercase" (click)="openPage('backmain/profile')">
                        <div>
                            
                            <span>{{ user.first_name + ' ' + user.last_name + ' - $' + user.totalCash}}</span>
                        </div>
                    </a>
                </li> -->
                <li  class="nav-link">
                    <a (click)="openPage('donate')"  data-toggle="collapse" [ngClass]="(currentRoute == '/donate') ? 'selected-page' : ''" data-target=".navbar-collapse.show" class="donate nav-link text-center" translate>DONATE</a></li>
                <li class="nav-item">
                    <select
                        class="form-control" 
                        #selectedLang 
                        (change)="switchLang(selectedLang.value)">
                    <option *ngFor="let language of translate.getLangList()" 
                        [value]="language.code"
                        [selected]="language.code === translate.getCurLang()">
                        {{ language.name }}
                    </option>
                    </select> 
                </li>
            </ul>
            
          </div>

          <ng-container *ngIf="currentRoute.includes('backmain')">
            <div class="m-auto">
            
            </div>
              <!-- <span class="ml-auto d-flex dropdown" *ngIf="shareinfoService.isLoggedIn() && currentRoute.includes('backmain')">
                      
                  <mat-slide-toggle color="primary"  [(ngModel)]="isChecked"  (ngModelChange)="modeChanged($event)">{{ mode == 'user' ? 'User Mode' : 'Admin Mode' }} </mat-slide-toggle>
              </span> -->
              <span class="d-flex dropdown" *ngIf="shareinfoService.isLoggedIn() && currentRoute.includes('backmain')">
                  <div class="d-flex align-items-center">
                    <div *ngIf="sharedUtilService.checkUserPermissions(this.user.id) == 'user'">
                        <a class="nav-link cart" data-toggle="collapse" style="color: rgb(171, 206, 53);" data-target=".navbar-collapse.show" (click)="showCart()" translate>
                            <i class="fa fa-shopping-cart" style="font-size : 1.5rem; position : relative;color: rgb(171, 206, 53)" aria-hidden="true"><span class="badge" *ngIf="cart_quantity > 0">{{ cart_quantity }}</span></i>
                        </a>
                      </div>
                      <div >
                        <a class="nav-link" data-toggle="collapse" style="color: black;" data-target=".navbar-collapse.show" (click)="logout()" translate>
                        LOGOUT</a>
                      </div>

                      <div style="color: black" class="nav-link notification" id="message-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" *ngIf="mode == 'user'" aria-expanded="false">
                        <i class="fa fa-envelope mr-3" style="font-size : 1.5rem; position : relative" aria-hidden="true"><span *ngIf="messages.length > 0"  class="badge">{{ messages.length }}</span></i>
                        
                        <i class="drop-caret fa fa-caret-down" style="font-size : 1.5rem" aria-hidden="true"></i>
                        
                    </div>
                    <div class="dropdown-menu dropdown-menu-lg-end dropdown-message p-3" aria-labelledby="message-dropdown" *ngIf="mode == 'user'">
                        <p class="title">Messages</p>
    
                        <a class="dropdown-item item-message" (click)="viewMessage(i)" *ngFor="let message of messages; index as i;" data-toggle="collapse" data-target=".navbar-collapse.show" >
                            <div class="d-flex">
                                <div class="mt-auto mb-auto ml-0 mr-3 message-container">
                                    {{ message.topic }}<br>
                                    <small>{{ message.AddTime ? message.AddTime : "No Date Available" }}</small>
                                </div>
                                <div class="mt-auto mb-auto ml-auto">
                                    <i class="fa fa-envelope" style="font-size : 1.5rem" aria-hidden="true" ></i>
                                </div>
                            </div>
                        </a>
                        <div class="empty-messages" *ngIf="messages.length == 0">No New Messages</div>
                    </div>
                  </div>
              </span>
            <div class="ml-3"></div>
            <div class="user-logged ml-3 d-flex" *ngIf="shareinfoService.isLoggedIn() && currentRoute.includes('backmain')">
              <select
              class="form-control m-auto" 
              #selectedLang 
              (change)="switchLang(selectedLang.value)">
                  <option *ngFor="let language of translate.getLangList()" 
                      [value]="language.code"
                      [selected]="language.code === translate.getCurLang()">
                      {{ language.name }}
                  </option>
              </select>
              
              <div class="ml-3"></div>
              <span class="m-auto">
                  <div class="font-weight-bold">
                      {{ user.first_name}}
                  </div>
                  <div style="text-wrap: nowrap;font-size: 16px;letter-spacing: -1px;">{{ 'EVCOINSLABEL' | translate }}</div>
              </span>
              <div class="ml-5"></div>
              <span class="m-auto font-weight-bold" *ngIf="mode == 'user'">
                  <div style="text-wrap: nowrap;font-size: 16px;letter-spacing: -1px;">{{ rankName }}</div>
                  <div>
                    <app-show-bytetoken [amount]="user.totalCash" style="margin-left: -5px;"></app-show-bytetoken>
                      <!--{{ user.totalCash | currency:''}}-->
                  </div>
              </span>
           </div>
          </ng-container>
          
      </nav>
</div>


