import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EvgApiService {

  private endPoint = environment.url;
  user:any=null;
  http_props:any;
  jsonHeader : any;
  extraheaders:any;
  downloadHeaders:any;
  extraheadersWithToken:any;
  uploadFileHeaders:any;

  constructor(private http: HttpClient) { 
    this.http_props = {
      url: this.endPoint,
      headers: {'Content-Type': 'application/json; charset=utf-8','Accept':'application/json'},
      method : 'GET',
      params:{},
      data:{}
    }
    this.extraheaders = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    this.downloadHeaders = new HttpHeaders({'Content-Type':'application/pdf; charset=utf-8'});
    //this.uploadFileHeaders = new HttpHeaders({'Content-Type':'multipart/form-data charset=utf-8; boundary="xxx-byte-health-xxx'});
    this.uploadFileHeaders = new HttpHeaders({'Content-Type': undefined});
    //this.uploadFileHeaders = new HttpHeaders({'Content-Type': undefined});
    this.extraheaders = this.extraheaders.set('Accept', 'application/json');
    this.extraheaders = this.extraheaders.set('Access-Control-Allow-Origin', '*');
    this.extraheadersWithToken = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    this.extraheadersWithToken = this.extraheadersWithToken.set('Accept', 'application/json');
    this.extraheadersWithToken = this.extraheadersWithToken.set('Access-Control-Allow-Origin', '*');
    if(localStorage.userToken) this.extraheadersWithToken = this.extraheadersWithToken.set('Authorization', 'Bearer ' + localStorage.userToken );
    this.uploadFileHeaders = this.uploadFileHeaders.set('Access-Control-Allow-Origin', '*');
    this.uploadFileHeaders = this.uploadFileHeaders.set('Accept', 'application/json');
    /*
    if(localStorage.userToken) {
      console.log('localStorage.userToken:',localStorage.userToken);
      this.uploadFileHeaders = this.uploadFileHeaders.set('Authorization', 'Bearer ' + localStorage.userToken );
    } 
    */
    
  }

  setHeaderToken(token)
  {
    this.extraheadersWithToken = this.extraheadersWithToken.set('Authorization', 'Bearer ' + token );
  }

  userRegister(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/register", obj,  { headers: this.extraheaders , withCredentials: true });
  }

  userLogin(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/login", obj,  { headers: this.extraheaders , withCredentials: true });
  }

  ewalletLogin(obj : any) : Observable<any> {
    return this.http.post<any>(this.endPoint + "/ewlogin", obj,  { headers: this.extraheaders , withCredentials: true });
  }

  getUser(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/user",  { headers: this.extraheadersWithToken });
  }

  getActiveUser(obj : any) : Observable<any> {
    
    return this.http.get<any>(this.endPoint + "/activeuser/" + (obj && obj.remember_token ? obj.remember_token : ''),   { headers: this.extraheaders });
  }

  getTeam(obj: any): Observable<any> {

   return this.http.get<any>(this.endPoint + "/teamTree",  { headers: this.extraheadersWithToken });
 }

  rootTree  (obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/rootTree",  { headers: this.extraheadersWithToken });
  }
  getSponsers(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getSponsers",  { headers: this.extraheadersWithToken });
  }

  getSponsor(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/sponsorTree",  { headers: this.extraheadersWithToken });
  }

 

  signOut(): Observable<any> {

    return this.http.post<any>(this.endPoint + "/logout", { headers: this.extraheaders , withCredentials: true });
  }

  isUserLoggedIn(obj: any): Observable<any> {

    let parameters = ( (obj && obj.pageId && obj.seconds ? '/' + (obj.pageId).replace(/\//g,'_') + '/' +  obj.seconds : ''));

    return this.http.get<any>(this.endPoint + "/whoau" + parameters , { headers: this.extraheadersWithToken , withCredentials: true });
    //return this.http.get<any>(this.endPoint + "/whoau" + (obj && obj.pageId && obj.seconds ? '?pageId=' + obj.pageId + '&seconds=' +  obj.seconds : '') , { headers: this.extraheaders , withCredentials: true });
  }

  getProductsForshoppingCart(obj:any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getProductsForshoppingCart", { headers: this.extraheaders , withCredentials: true });
  }
  
  forPurchase(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/purchase", { headers: this.extraheaders , withCredentials: true });
  }

  placeOrder(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/placeOrder", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  doPurchase(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/purchase", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }
  doPurchaseOnly(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/purchaseOnly", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }
  validatePurchaseOnly(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/validatePurchaseOnly", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }
  
  validatePurchaseOnly8888(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/validatePurchaseOnly8888", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  findNews(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/news/" + (obj && obj.title ? obj.title : '') , { headers: this.extraheaders , withCredentials: true });
  }

  editNews(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/editnews", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }
  
  getBalance(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/balance",  { headers: this.extraheadersWithToken });
  }

  getRunningBalance(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getAccountInfo",  { headers: this.extraheadersWithToken });
  }
 
 
   updateUser(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/updateUser", obj ,{ headers: this.extraheadersWithToken , withCredentials: true });
  }

  uploadUserPhoto(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/uploadUserPhoto", obj ,{ headers: this.extraheadersWithToken , withCredentials: true });
  }
  // Get Carousel Data
  getTestimonialData(obj: any): Observable<any> {

    return this.http.get<any>("assets/data/testimonials/testimonials.json", obj);
  }

  getSignedKeysForUpload(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/people/signed-upload", { withCredentials: true });
  }

  resetPassword(obj): Observable<any> {
    return this.http.post(this.endPoint + '/resetpassword', obj,{ headers: this.extraheaders , withCredentials: true });
  }

  updatePassword(obj): Observable<any> {
    return this.http.post(this.endPoint + '/updatepassword', obj,{ headers: this.extraheadersWithToken , withCredentials: true });
  }

  updateTradePassword(obj): Observable<any> {
    return this.http.post(this.endPoint + '/updateTredePassword', obj,{ headers: this.extraheadersWithToken , withCredentials: true });
  }

  updateEwalletPassword(obj): Observable<any> {
    return this.http.post(this.endPoint + '/ewUpdatePW', obj,{ headers: this.extraheadersWithToken , withCredentials: true });
  }

  resetEWPassword (obj): Observable<any> {
    return this.http.post(this.endPoint + '/resetEWPassword', obj,{ headers: this.extraheadersWithToken , withCredentials: true });
  }

  verifyPasswordCode(obj): Observable<any> {
    return this.http.post(this.endPoint + '/verifypassword', obj,{ headers: this.extraheaders , withCredentials: true });
  }

  forgotPassword(obj): Observable<any> {
    return this.http.post(this.endPoint + '/people/forgot/', obj);
  }

  accountActivation(obj): Observable<any> {
    return this.http.post(this.endPoint + '/activateaccount', obj);
  }

  getMainMenu(): Observable<any> {
    return this.http.get<any>("assets/config/menus/mainmenu.json");
  }

  getUserBackMenu(): Observable<any> {
    return this.http.get<any>("assets/config/menus/userbackmenu.json");
  }

  getAdminBackMenu(): Observable<any> {
    return this.http.get<any>("assets/config/menus/adminbackmenu.json");
  }


  getEducationLevels(): Observable<any> {
    return this.http.get<any>("assets/config/information/educationLevels.json");
  }

  getLanguages(): Observable<any> {
    return this.http.get<any>("assets/config/languages/languages.json");
  }

  getCountries(): Observable<any> {
    return this.http.get<any>("assets/config/country/countryList.json");
  }

  getCountryList(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getCountryList",  { headers: this.extraheadersWithToken });
  }

  getProvinceList(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getProvinces?country=" + obj.country,  { headers: this.extraheadersWithToken });
  }


  getProvinces(): Observable<any> {
    return this.http.get<any>("assets/config/country/provinces.json");
  }

  getSecretQuestions(): Observable<any> {
    return this.http.get<any>(this.endPoint + "/secretq",  { headers: this.extraheaders });
  }

  getRankHistory(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/balance",  { headers: this.extraheadersWithToken });
  }

  updateSecret(obj): Observable<any> {
    return this.http.post(this.endPoint + '/secret', obj,{ headers: this.extraheadersWithToken , withCredentials: true });
  }

  checkSecretAnswer(obj): Observable<any> {
    return this.http.post(this.endPoint + '/checkSecretAnswer', obj,{ headers: this.extraheadersWithToken , withCredentials: true });
  }

  getNewOrders(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/newOrders",  { headers: this.extraheadersWithToken });
  }

  getMyOrders(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/myOrders",  { headers: this.extraheadersWithToken });
  }

  getTeamNewOrders(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/teamNewOrders",  { headers: this.extraheadersWithToken });
  }

  getTeamOrders(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/teamOrders",  { headers: this.extraheadersWithToken });
  }

  doEWTransfers(obj: any): Observable<any> {
    return this.http.post(this.endPoint + '/doEWTransfers', obj,{ headers: this.extraheadersWithToken , withCredentials: true });
  }

  getBulletinBoards(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getBulletinBoards",  { headers: this.extraheadersWithToken });
  }

  getPerformanceGrowthBulletinRanks(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getGowthBulletinRank",  { headers: this.extraheadersWithToken });
  }

  getPromotions(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getPromotions",  { headers: this.extraheadersWithToken });
  }

  getBonus(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getBonus?" + "dateId=" + obj.dateId  ,  { headers: this.extraheadersWithToken });
  }

  getNewMessages(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getNewMessages",  { headers: this.extraheadersWithToken });
  }

  getAllMessages(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getAllMessages",  { headers: this.extraheadersWithToken });
  }

  postAskQuestion(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/postAskquestion", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getFAQ(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getFAQ",  { headers: this.extraheadersWithToken });
  }

  getInquiries(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getReplayMessages",  { headers: this.extraheadersWithToken });
  }

  clearReadFlag(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/clearReadFlag", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  postApplyChange(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/postApplyChange", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  checkMonthFeeIsPaid (obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/checkMonthFeeIsPaid",  { headers: this.extraheadersWithToken });
  }

  getSalesReport(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getSalesReport/" + obj.fromDate + "/" + obj.toDate + "/" + obj.group, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getTaxesReport(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getTaxesReport/" + obj.fromDate + "/" + obj.toDate + "/" + obj.group, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getOrdersForTaxesReports(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getOrdersForTaxesReports/" + obj.fromDate + "/" + obj.toDate + "/" + obj.group, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getPayoutBonus(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/payOutBonus/" + obj.fromDate + "/" + obj.toDate + "/" + obj.group, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  payMonthlyFee(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/payMonthlyFee", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getCart(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getCart",  { headers: this.extraheadersWithToken });
  }

  sentCoinsToUser(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/sentCoinsToUser", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  sentCoinsToEWallet(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/sentCoinsToEWallet", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  receiveEvCoin(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/receiveEvCoin", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getReplayMessages(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getReplayMessages",  { headers: this.extraheadersWithToken });
  }

  replayAskquestion(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/replayAskquestion", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getBulletinBoard(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getBulletinBoard",  { headers: this.extraheadersWithToken });
  }

  postBulletinBoard(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/postBulletinBoard", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  modifyBulletinBoard(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/modifyBulletinBoard", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getNews(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/news",  { headers: this.extraheadersWithToken });
  }

  postNews(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/news", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  modifyNews(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/editnews", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getEvents(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getEvents",  { headers: this.extraheadersWithToken });
  }

  postEvent(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/postEvent", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  modifyEvent(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/modifyEvent", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getAdminMessages(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getAdminMessages",  { headers: this.extraheadersWithToken });
  }

  postAdminMessage(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/postMessage", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  modifyAdminMessage(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/modifyMessage", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getPGBrank(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getPGBrank",  { headers: this.extraheadersWithToken });
  }

  getGLBrank(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getGLBrank",  { headers: this.extraheadersWithToken });
  }

  publishPGB(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/publishPGB", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  publishGLB(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/publishGLB", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getBonusSetting(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getBonusSetting",  { headers: this.extraheadersWithToken });
  }

  generateRankBulletin(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/generateRankBulletin",  { headers: this.extraheadersWithToken });
  }

  getBonusPool(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getBonusPool",  { headers: this.extraheadersWithToken });
  }

  setBonusPool(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/setBonusPool", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }


  getOrderHead(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getOrderHead" + "?groupId=" + obj.groupId,  { headers: this.extraheadersWithToken });
  }

  getOrderDetail(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getOrderDetail" + "?groupId=" + obj.groupId,  { headers: this.extraheadersWithToken });
  }

  withdrawReport(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/withdrawReport",  { headers: this.extraheadersWithToken });
  }

  userWithdrawDetail(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/userWithdrawDetail"  + "?userId=" + obj.userId + "&setYYYYMM=" + obj.YearMonth,  { headers: this.extraheadersWithToken });
  }

  userTransferRecords(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/userTransferRecords",  { headers: this.extraheadersWithToken });
  }

  userTransferDetail(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/userTransferDetail" + "?userId=" + obj.userId,  { headers: this.extraheadersWithToken });
  }

  cancelOrdersReport(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/cancelOrdersReport",  { headers: this.extraheadersWithToken });
  }

  monthlyCanceledOrders(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/monthlyCanceledOrders" + "?setYYYYMM=" + obj.YearMonth,  { headers: this.extraheadersWithToken });
  }

  lockUserAccount(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/lockUserAccount", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  unlockUserAccount(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/unlockUserAccount", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  AYBpublished(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/AYBpublished",  { headers: this.extraheadersWithToken });
  }

  sendTokensToUserFromAYB(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/sendTokensToUserFromAYB", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  withdrawFromAYB(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/withdrawFromAYB", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  updateUserRank(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/updateUserRank", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  updateDownlines(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/updateDownlines", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  rankReport(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/rankReport",  { headers: this.extraheadersWithToken });
  }

  getProducts(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getProducts",  { headers: this.extraheadersWithToken });
  }

  getProduct(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getProducts/" + obj.pId,  { headers: this.extraheadersWithToken });
  }

  deleteProduct(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/deleteProduct", obj, { headers: this.extraheadersWithToken });
  }

  addNewProduct(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/addNewProduct", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  modifyProduct(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/modifyProduct", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  uploadProductImage(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/uploadProductImage", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  uploadMyShoppingImages(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/uploadMyShoppingImages", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }
  
  
  updatePayFeeSetting(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/updatePayFeeSetting", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getCardsInfo(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getCardsInfo/",  { headers: this.extraheadersWithToken });
  }

  addCart(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/addCart", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  addCartFromPurchase(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/addCartFromPurchase", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  findTaxRates(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/findTaxRates?" + "country=" + obj.country +"&state_code=" + obj.state_code,  { headers: this.extraheadersWithToken });
  }

  getTaxRates(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getTaxRates",  { headers: this.extraheadersWithToken });
  }

  deleteCartByPID(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/deleteCartByPID", obj ,{ headers: this.extraheadersWithToken });
  }

  clearCart(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/clearCart", obj ,{ headers: this.extraheadersWithToken });
  }
  
  modifyCart(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/modifyCart", obj ,{ headers: this.extraheadersWithToken });
  }
  modifyCartBatch(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/modifyCartBatch", obj ,{ headers: this.extraheadersWithToken });
  }

  getRequestlist(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getRequestlist" + "?infoType=" + obj.infoType,  { headers: this.extraheadersWithToken });
  }

  cancelOrder(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/cancelOrder", obj ,{ headers: this.extraheadersWithToken });
  }

  getRequestInfo(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getRequestInfo" + "?id=" + obj.id,  { headers: this.extraheadersWithToken });
  }

  doneWithdraw(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/doneWithdraw", obj ,{ headers: this.extraheadersWithToken });
  }

  cancelWithdraw(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/cancelWithdraw", obj ,{ headers: this.extraheadersWithToken });
  }

  findNewOrderGroups(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/findNewOrderGroups" ,  { headers: this.extraheadersWithToken });
  }

  findAllOrderGroups(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/findAllOrderGroups" + (obj && obj.userId ? '/?userId=' + obj.userId + (obj && obj.orderType? '&orderType=' + obj.orderType : '') : obj && obj.orderType? '?orderType=' + obj.orderType : '') ,  { headers: this.extraheadersWithToken });
  }

  findNewOrderGroupsAll(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/findNewOrderGroupsAll" ,  { headers: this.extraheadersWithToken });
  }

  getMyDownLinelist(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getMyDownLinelist" ,  { headers: this.extraheadersWithToken });
  }

  updateUserDistributorFlag(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/updateUserDistributorFlag", obj ,{ headers: this.extraheadersWithToken });
  }

  secretQuestionUpdate(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/secretQuestionUpdate", obj ,{ headers: this.extraheadersWithToken });
  }

  getUserSecretQuestions(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getUserSecretQuestions" ,  { headers: this.extraheadersWithToken });
  }

  getMyCoinTransfers(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getMyCoinTransfers" ,  { headers: this.extraheadersWithToken });
  }


  findOrderByGroupId(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/findOrderByGroupId" ,  { headers: this.extraheadersWithToken });
  }

  getCompanyMassages(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getCompanyMassages" ,  { headers: this.extraheadersWithToken });
  }

  getMessageDetail(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getMessageDetail?id=" + obj.id + "&msg_type=" + obj.msg_type ,  { headers: this.extraheadersWithToken });
  }

  getMyRankName(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getMyRankName" ,  { headers: this.extraheadersWithToken });
  }

  getUserShippingAddresses(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getUserShippingAddresses",  { headers: this.extraheadersWithToken });
  }

  getShippingAddressesByEmail(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getShippingAddressesByEmail?email="+obj.email ,  { headers: this.extraheadersWithToken });
  }

  deleteShippingAddress(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/deleteShippingAddress" , obj ,  { headers: this.extraheadersWithToken });
  }
  deleteShippingAddress8888(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/deleteShippingAddress8888" , obj ,  { headers: this.extraheadersWithToken });
  }

  postShippingAddress(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/postShippingAddress", obj ,{ headers: this.extraheadersWithToken });
  }
  postShippingAddress8888(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/postShippingAddress8888", obj ,{ headers: this.extraheadersWithToken });
  }

  getPaidMonthlyFeeHistory(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getPaidMonthlyFeeHistory" ,  { headers: this.extraheadersWithToken });
  }

  eWalletlog(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/eWalletlog" ,  { headers: this.extraheadersWithToken });
  }

  getAdminUsersConfig (obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getAdminUsersConfig " ,  { headers: this.extraheadersWithToken });
  }

  getULevelLogs(obj : any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getuLevelLog " ,  { headers: this.extraheadersWithToken });
  }

  getRequestByGroupList(obj : any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getRequestByGroupList?requestType=" + obj.requestType ,  { headers: this.extraheadersWithToken });
  }

  getRequestByGroupDetail(obj : any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getRequestByGroupDetail?ticketNo=" + obj.ticketNo ,  { headers: this.extraheadersWithToken });
  }

  getCancelGroupIds(obj : any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getCancelGroupIds" ,  { headers: this.extraheadersWithToken });
  }

  postPromotion(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/postPromotion", obj ,{ headers: this.extraheadersWithToken });
  }

  modifyPromotion(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/modifyPromotion", obj ,{ headers: this.extraheadersWithToken });
  }

  getApplications(obj : any): Observable<any> {
      return this.http.get<any>(this.endPoint + "/getApplications" + (obj.isClosed >= 0 ? ('?isClosed=' + obj.isClosed) : '') ,  { headers: this.extraheadersWithToken });
  }

  getApplication(obj : any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getApplication?id=" + obj.id ,  { headers: this.extraheadersWithToken });
  }

  approveApplication(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/approvalApplication", obj ,{ headers: this.extraheadersWithToken });
  }

  rejectApplication(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/rejectApplication", obj ,{ headers: this.extraheadersWithToken });
  }

  getRankPromotedUsers(obj : any): Observable<any> {
      return this.http.get<any>(this.endPoint + "/getRankPromotedUsers",  { headers: this.extraheadersWithToken });
  }

  getBankInfo(obj : any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getBankInfo",  { headers: this.extraheadersWithToken });
  }

  getBankInfoById(obj : any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getBankInfoById?bank_id=" + obj.bank_id,  { headers: this.extraheadersWithToken });
  }

  purchaseByteTokens(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/purchaseByteTokens", obj ,{ headers: this.extraheadersWithToken });
  }

  getPurchaseByteTokensLog(obj : any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getPurchaseByteTokensLog",  { headers: this.extraheadersWithToken });
  }

  getProductReview(obj : any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getReviewResults/" + obj.pId,  { headers: this.extraheadersWithToken });
  }

  getProductReviews(obj : any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getReviewResults",  { headers: this.extraheadersWithToken });
  }

  addProductReview(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/addNewReview", obj ,{ headers: this.extraheadersWithToken });
  }

  getCoupons(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getCoupons" ,{ headers: this.extraheadersWithToken });
  }

  findCommonCoupon(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/findCommonCoupon" ,{ headers: this.extraheadersWithToken });
  }

  generateCoupon(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/generateCoupon" ,{ headers: this.extraheadersWithToken });
  }

  addCouponTicket(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/addCouponTicket", obj ,{ headers: this.extraheadersWithToken });
  }

  postCouponTicket(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/postCouponTicket", obj ,{ headers: this.extraheadersWithToken });
  }

  deleteCouponTicket(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/deleteCouponTicket", obj ,{ headers: this.extraheadersWithToken });
  }

  submitCoupon(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/submitCoupon", obj ,{ headers: this.extraheadersWithToken });
  }

  getReviews(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getReviews" ,{ headers: this.extraheadersWithToken });
  }

  getUserProfile(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getUserProfile?userId=" + obj.userId ,{ headers: this.extraheadersWithToken });
  }

  updateUserProfile(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/updateUserProfile", obj ,{ headers: this.extraheadersWithToken });
  }

  pickOrderForDelivery(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/pickOrderForDelivery" ,{ headers: this.extraheadersWithToken });
  }

  saveDeliveryTicket(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/saveDeliveryTicket", obj ,{ headers: this.extraheadersWithToken });
  }

  printDeliveryTicket(obj: any): Observable<any> {
    return this.http.post<any>(this.endPoint + "/printDeliveryTicket", obj ,{ headers: this.extraheadersWithToken });
  }

  getDeliveryTickets(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getDeliveryTickets" ,{ headers: this.extraheadersWithToken });
  }

  getDeliveryProducts(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getDeliveryProducts?orderGroupId=" + obj.orderGroupId ,{ headers: this.extraheadersWithToken });
  }

  verifyUser(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/verifyUser?userId=" + obj.userId ,{ headers: this.extraheadersWithToken });
  }

  getBonusByAdmin(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getBonusByAdmin?userId=" + obj.userId ,{ headers: this.extraheadersWithToken });
  }

  getUserOrdersByAdmin(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getUserOrdersByAdmin?userId=" + obj.userId ,{ headers: this.extraheadersWithToken });
  }

  getByteTokenForCancel(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getByteTokenForCancel" ,{ headers: this.extraheadersWithToken });
  }

  getRefundBTRequest(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getRefundBTRequest" ,{ headers: this.extraheadersWithToken });
  }

  approvalRefundByteTokens(obj: any){
    return this.http.post<any>(this.endPoint + "/approvalRefundByteTokens", obj ,{ headers: this.extraheadersWithToken });
  }

  rejectRefundByteTokens(obj: any)
  {
    return this.http.post<any>(this.endPoint + "/rejectRefundByteTokens", obj ,{ headers: this.extraheadersWithToken });
  }

  getEmailList(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getEmailList" ,{ headers: this.extraheadersWithToken });
  }

  getEmailById(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getEmailById?id=" + obj.id ,{ headers: this.extraheadersWithToken });
  }

  getDistributors(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getDistributors" ,{ headers: this.extraheadersWithToken });
  }

  postEmail (obj: any)
  {
    return this.http.post<any>(this.endPoint + "/postEmail", obj ,{ headers: this.extraheadersWithToken });
  }

  sendEmailToAll (obj: any)
  {
    return this.http.post<any>(this.endPoint + "/sendEamilToAll", obj ,{ headers: this.extraheadersWithToken });
  }

  sendEmailToUsers (obj: any)
  {
    return this.http.post<any>(this.endPoint + "/sendEamilToUsers", obj ,{ headers: this.extraheadersWithToken });
  }

  sendEmailToTests (obj: any)
  {
    return this.http.post<any>(this.endPoint + "/sendEmailToTests", obj ,{ headers: this.extraheadersWithToken });
  }

  getUsersByRank(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getUsersByRank?rank=" + obj.rank ,{ headers: this.extraheadersWithToken });
  }

  postSubscribe (obj: any)
  {
    return this.http.post<any>(this.endPoint + "/postSubscribe", obj ,{ headers: this.extraheadersWithToken });
  }

  getUserRankInfo(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getUserRankInfo?userId=" + obj.userId ,{ headers: this.extraheadersWithToken });
  }

  getUserVerifyInfo(obj: any)  {
    return this.http.get<any>(this.endPoint + "/getUserVerifyInfo" ,{ headers: this.extraheadersWithToken });
  }

  postMaintainInfo (obj: any)
  {
    return this.http.post<any>(this.endPoint + "/postMaintainInfo", obj ,{ headers: this.extraheadersWithToken });
  }

  getMaintainInfo(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getMaintainInfo" ,{ headers: this.extraheadersWithToken });
  }
  

  getBlogPosts (obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getBlogPosts",{ headers: this.extraheadersWithToken });
  }


  getInvoiceLink (obj: any)
  {
    if (obj.id) {
      return this.http.get<any>(this.endPoint + "/getInvoiceLink?id=" + obj.id ,{ headers: this.extraheadersWithToken });
    } else {
      return this.http.get<any>("assets/data/returnempty.json",obj);
    }
  }

  findInvoiceByToken (obj: any)
  {
    return this.http.get<any>(this.endPoint + "/findInvoiceByToken?access_token=" + obj.id ,{ headers: this.extraheadersWithToken });
  }

  getInvoiceLinks (obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getInvoiceLinks?infoType=" + obj.infoType, { headers: this.extraheadersWithToken });
  }

  postInvoiceLink (obj: any)
  {
    return this.http.post<any>(this.endPoint + "/postInvoiceLink", obj ,{ headers: this.extraheadersWithToken });
  }

  getWholesale (obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getWholesale?id=" + obj.id ,{ headers: this.extraheadersWithToken });
  }

  getWholesales (obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getWholesales" ,{ headers: this.extraheadersWithToken });
  }

  postWholesale (obj: any)
  {
    return this.http.post<any>(this.endPoint + "/postWholesale", obj ,{ headers: this.extraheadersWithToken });
  }

  getWholeTaxInfo (obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getWholeTaxInfo?id=" + obj.id ,{ headers: this.extraheadersWithToken });
  }

  postPayForInvice(obj:any) {
    return this.http.post<any>(this.endPoint + "/postPayForInvice", obj ,{ headers: this.extraheadersWithToken });
  }

  getByteCart (obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getByteCart", { headers: this.extraheadersWithToken });
  }

  getMyCartInfo (obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getMyCartInfo?MemberNo=" + obj.MemberNo ,{ headers: this.extraheadersWithToken });
  }

  postByteCart(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/postByteCart", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getMyShoppingImages (obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getMyShoppingImages?MemberNo=" + obj.MemberNo ,{ headers: this.extraheadersWithToken });
  }

  deleteMyShoppingImage(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/deleteMyShoppingImage", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  getProfileUpdateHistory(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + ("/getProfileUpdateHistory" + ( obj.userId ? '?userId=' + obj.userId : '')) ,{ headers: this.extraheadersWithToken });

  }

  reopenRequest(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/reopenRequest", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  verifyTradePass(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + ("/verifyTradePass?tradePass=" + obj.tradePass ) ,{ headers: this.extraheadersWithToken });

  }

  getAssetsDailyHistory(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getAssetsDailyHistory",{ headers: this.extraheadersWithToken });

  }
  getSuspectUserBalanceDetail(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getSuspectUserBalanceDetail" + '?historyId='+obj.historyId,{ headers: this.extraheadersWithToken });
  }

  getUploadedDocs(obj: any): Observable<any> {
    let paramters =  ( obj.infoType ? '?infoType=' + obj.infoType : '');
    if (obj.id) {
      paramters = paramters + (paramters=='' ? '?id=' : '&id=') + + obj.id ;
    }

    return this.http.get<any>(this.endPoint + "/getUploadedDocs" + paramters ,{ headers: this.extraheaders,responseType: 'json'});
  }

  downloadDocuments(obj: any): Observable<any> {
    return this.http.get<any>(this.endPoint + "/downloadDocuments" + '?id='+obj.id,{ headers: this.downloadHeaders,responseType: 'blob' as 'json'});
  }
  
  downloadFile(obj:any){
      return this.http.post((this.endPoint +'/downloadDocuments'),obj,{
          responseType : 'blob',
          headers:new HttpHeaders().append('Content-Type','application/json')
      });
  }

  postUploadFile(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/postUploadFile", obj, { headers: this.uploadFileHeaders ,reportProgress: true,responseType: 'json', withCredentials: true });
  }

  doUploadFile(obj: any):Observable<any> {
      //  return this.httpClient.post<any>(this.SERVER_URL, formData, { eportProgress: true, observe: 'events' });  

      return this.http.post<any>(this.endPoint + "/doUploadFile", obj, { headers: this.uploadFileHeaders ,responseType: 'json'});
  }

  postUploadDoc(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/postUploadDoc", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }

  deleteUploadedDoc(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/deleteUploadedDoc", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }
  
  getOfferedProducts (obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getOfferedProducts?MemberNo=" + obj.MemberNo ,{ headers: this.extraheaders });
  }

  geMyOferedProductsForByteCart (obj: any)
  {
    return this.http.get<any>(this.endPoint + "/geMyOferedProductsForByteCart?MemberNo=" + obj.MemberNo ,{ headers: this.extraheaders });
  }

  getTermAutoship(): Observable<any> {
    return this.http.get<any>("assets/config/information/termForAutoship.json");
  }

  runAutoshipProcess(obj: any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/runAutoshipProcess", obj, { headers: this.extraheadersWithToken , withCredentials: true });
  }
  getBenefits(): Observable<any> {
    return this.http.get<any>("assets/data/benefits.json");
  }
  getAwardOptionsMessage(obj: any){
    return this.http.get<any>(this.endPoint + "/getAwardOptionsMessage?AwardId=" + obj.AwardId +'&AwardValue=' + obj.AwardValue ,{ headers: this.extraheaders });

  }
  searchSortProducts(obj: any){
    return this.http.get<any>(this.endPoint + "/searchSortProducts?sortType=" + obj.sortType + (obj.search ? '&search=' + obj.search:'') + (obj.show_category ? '&show_category=' + obj.show_category:'') + (obj.forAll ? '&forAll=1':''),{ headers: this.extraheaders });

  }
  getMyAutoshipProducts(obj: any){
    return this.http.get<any>(this.endPoint + "/getMyAutoshipProducts" ,{ headers: this.extraheaders });

  }
  updateMyAutoshipOffer(obj:any){

    return this.http.post<any>(this.endPoint + "/updateMyAutoshipOffer", obj, { headers: this.extraheadersWithToken , withCredentials: true });

  }
  updatedPreSponsor(obj:any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/updatedPreSponsor",obj, { headers: this.extraheaders , withCredentials: true });
  }
  findAllUserIdFromOrders(obj: any){
    return this.http.get<any>(this.endPoint + "/findAllUserIdFromOrders" ,{ headers: this.extraheaders });
  }
  getOrderInfo(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getOrderInfo" + "?groupId=" + obj.groupId,  { headers: this.extraheadersWithToken });
  }

  findPayoutBonusByOrderId(obj: any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/findPayoutBonusByOrderId" + "?orderIds=" + obj.orderIds,  { headers: this.extraheadersWithToken });
  }

  deleteCardById(obj:any): Observable<any> {
    
    return this.http.post<any>(this.endPoint + "/deleteCardById",obj, { headers: this.extraheaders , withCredentials: true });
  }

  getAllCardsInfo(obj:any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getAllCardsInfo/",  { headers: this.extraheadersWithToken });
  }
  updatedCreditCard(obj:any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/updatedCreditCard",obj, { headers: this.extraheaders , withCredentials: true });
  }

  updatedDefaultCreditCard(obj:any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/updatedDefaultCreditCard",obj, { headers: this.extraheaders , withCredentials: true });
  }

  getOnePlacerforNewuser(obj:any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getOnePlacerforNewuser"+ "?pre_sponsor=" + obj.pre_sponsor,  { headers: this.extraheadersWithToken });
  }

  createPaymentIntent(obj:any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/createPaymentIntent",obj, { headers: this.extraheaders , withCredentials: true });
  }

  getLastContactInfo(obj:any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getLastContactInfo",  { headers: this.extraheadersWithToken });
  }

  getLastShippingAddress(obj:any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getLastShippingAddress",  { headers: this.extraheadersWithToken });
  }

  //david added shipping fee
  getShippingFee(postCode:string, country:string, parcelChar:number): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getShippingFee/" + postCode + '/' + country + '/' + parcelChar,  { headers: this.extraheadersWithToken });
  }

  getByteTokenTrxHistory(): Observable<any> {
    return this.http.get<any>(this.endPoint + "/balanceLogs",  { headers: this.extraheadersWithToken });
  }


  getWithdrawalRequests(): Observable<any> {
    return this.http.get<any>(this.endPoint + "/getWithdrawalRequests",  { headers: this.extraheadersWithToken });
  }

  lockWithdrawalRequest(id: number): Observable<any> {
    return this.http.post<any>(this.endPoint + "/lockWithdrawalRequest", {id:id}, { headers: this.extraheadersWithToken });
  }

  cancelWithdrawalRequest(id: number): Observable<any> {
    return this.http.post<any>(this.endPoint + "/cancelWithdrawalRequest", {id:id}, { headers: this.extraheadersWithToken });
  }

  getShippingFeeByCountryCode(obj:any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getShippingFeeByCountryCode?countryCode=" + obj.CountryCode +"&postCode="+ obj.PostCode + "&parcelChar=" + obj.parcelChar,  { headers: this.extraheadersWithToken });
  }

  getSponsorInfo(obj:any): Observable<any> {

    return this.http.get<any>(this.endPoint + "/getSponsorInfo?sponsorId=" + obj.sponsorId,  { headers: this.extraheadersWithToken });
  }
  
  setShowMenueByUserId(obj:any): Observable<any> {

    return this.http.post<any>(this.endPoint + "/setShowMenueByUserId",obj, { headers: this.extraheaders , withCredentials: true });
  }


  getUserProfileDetail(obj: any)
  {
    return this.http.get<any>(this.endPoint + "/getUserProfileDetail?userId=" + obj.userId ,{ headers: this.extraheadersWithToken });
  }
  
  transferToCurrentBalance(obj: any): Observable<any> {
    return this.http.post(this.endPoint + '/transferToCurrentBalance', obj,{ headers: this.extraheadersWithToken , withCredentials: true });
  }

}
