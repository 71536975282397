<div class="container">
    <div class="custom-nav">
        <nav class="navbar navbar-expand-lg navbar-dark">
                <div>
                    
                </div>
             
                <button class="navbar-toggler" type="button" data-toggle="collapse"  data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto mr-auto" *ngIf="shareinfoService.isLoggedIn() && mode == 'user'" >
                    <li class="nav-item" *ngFor="let menu of userBackMenus; index as i;" [ngClass]="menu.submenus.length > 0 ? 'dropdown' : ''">
                        <ng-container *ngIf="!menu.special">
                            <ng-container *ngIf="menu.need_purchase && (user.pTimes > 0  || user.showMenus==1)">
                                <a class="nav-link" data-toggle="dropdown" [attr.data-target]="menu.submenus.length > 0 ? '' : '.navbar-collapse.show'"  (click)="!(menu.submenus.length > 0) && openPage('backmain/' + menu.slug)" translate>{{ menu.name }}</a>

                                <div class="dropdown-menu" aria-labelledby="navbarDropdown1" *ngIf="menu.submenus.length > 0">
                                    <a class="dropdown-item" *ngFor="let submenu of menu.submenus; index as i;" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/' + submenu.slug)"  [ngClass]="isActiveRoute(submenu.slug)"  translate>{{ submenu.name }}</a>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!menu.need_purchase">
                                <a class="nav-link" data-toggle="dropdown" [attr.data-target]="menu.submenus.length > 0 ? '' : '.navbar-collapse.show'" (click)="!(menu.submenus.length > 0) && openPage('backmain/' + menu.slug)" translate>{{ menu.name }}</a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown1" *ngIf="menu.submenus.length > 0">
                                    <ng-container *ngFor="let submenu of menu.submenus; index as i;">
                                        <a class="dropdown-item"  data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/' + submenu.slug)"  [ngClass]="isActiveRoute(submenu.slug)" *ngIf="!submenu.need_purchase || (submenu.need_purchase && (user.pTimes > 0 || user.showMenus==1))" translate>{{ submenu.name }}</a>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                    </li>
                <ng-container *ngIf="eWalletLogin">
                    <li class="nav-item dropdown">
                        <a translate class="nav-link" id="navbarDropdown4" role="button" data-toggle="dropdown" >
                            EWALLET
                        </a>
                        <!--
                        <a translate class="nav-link" id="navbarDropdown4" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                            EWALLET
                        </a>

                        <div *ngIf="shareinfoService.IsEwalletLoggedIn()" class="dropdown-menu" aria-labelledby="navbarDropdown4">
                        -->
                        <div  class="dropdown-menu" aria-labelledby="navbarDropdown4">
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/ewallet-overview')" [ngClass]="isActiveRoute('ewallet-overview')" translate>EWALLETSUB00</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/renew-ewallet-password')" [ngClass]="isActiveRoute('renew-ewallet-password')" translate>EWALLETSUB0</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/personal-profile')" [ngClass]="isActiveRoute('account')" translate>EWALLETSUB1</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/trade-password')" [ngClass]="isActiveRoute('trade-password')" translate>EWALLETSUB2</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/ewallet-balance')" [ngClass]="isActiveRoute('ewallet-balance')" translate>EWALLETSUB7</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/balance-record')" [ngClass]="isActiveRoute('balance-record')" translate>EWALLETSUB3</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/withdrawal-request')" [ngClass]="isActiveRoute('withdrawal-request')" translate>EWALLETSUB4</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/withdrawal-request-history')" [ngClass]="isActiveRoute('withdrawal-request-history')" translate>EWALLETSUB41</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/transfer-to-current-balance')" [ngClass]="isActiveRoute('transfer-to-current-balance')" translate>EWALLETSUB5</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/purchase-byte-tokens')" [ngClass]="isActiveRoute('purchase-byte-tokens')" translate>EWALLETSUB8</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" translate (click)="eWalletLogout()">EWALLETSUB6</a>
                        </div>
                    </li>
                </ng-container>
                <ng-container *ngIf="!eWalletLogin">
                    <li class="nav-item">
                        <a class="nav-link cart" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('ewallet-login')" translate>EWALLET</a>
                    </li>
                </ng-container>
                <!-- 
                        <li class="nav-item">
                            <a class="nav-link cart" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('cart')" translate>
                                <i class="fa fa-shopping-cart mr-3" style="font-size : 1.2rem; position : relative" aria-hidden="true"><span class="badge" *ngIf="cart_quantity > 0">{{ cart_quantity }}</span></i>
                            CART</a>
                        </li>

                        <li class="nav-item">
                        <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="logout()" translate>LOGOUT</a>
                    </li> 
                    -->
                </ul>

                <ul class="navbar-nav ml-auto mr-auto" *ngIf="shareinfoService.isLoggedIn() && mode != 'user'">
                    <li class="nav-item" *ngFor="let menu of adminBackMenus; index as i;" [ngClass]="menu.submenus.length > 0 ? 'dropdown' : ''">
                        <ng-container *ngIf="!menu.special && menu.permissions.includes(mode) && !menu.hidden" >
                            <a class="nav-link" data-toggle="dropdown"  [attr.data-target]="menu.submenus.length > 0 ? '' : '.navbar-collapse.show'" (click)="!(menu.submenus.length > 0) && openPage('backmain/admin/' + menu.slug)" translate>{{ menu.name }}</a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown1" *ngIf="menu.submenus.length > 0">
                                <a class="dropdown-item" *ngFor="let submenu of menu.submenus; index as i;" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="openPage('backmain/admin/' + submenu.slug)"  [ngClass]="isActiveRoute(submenu.slug)"  translate>{{ submenu.name }}</a>
                            </div>
                        </ng-container>
                        
                    </li>
                    
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="logout()" translate>LOGOUT</a>
                    </li>
                </ul>
              </div>
            
        </nav>
    </div>
    <div class="main-container">
        <div class="nav-route" *ngIf="!isPrinting">
            <span *ngFor="let nav of navStack; index as i;" >
                > <span class="font-weight-bold mr-2 ml-2" translate >{{ nav }}</span>
            </span>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>